import ReactPlayer from "react-player";

import {
  Box,
  Button,
  ModalWindow,
  ButtonWithDropdown,
} from "components/atomsKit/Elements";
import { NameonChat } from "components/designAtom/Elements";

// import cn from "classnames";

// @ts-ignore
import classes from "./ModalVideoPlayer.module.scss";

interface IProps {
  className?: string;
  isOpen?: any;
  closeModalVideo: any;
  handleLike?: any;
  handleLetters?: any;
  handleChat?: any;
  handleSendGift?: any;
  isLikeGirl?: boolean;

  data: any;
}

const ModalVideoPlayer = ({
  isOpen,
  data,
  closeModalVideo,
  handleLike,
  handleLetters,
  handleChat,
  handleSendGift,
  isLikeGirl,
  className,
}: IProps) => {
  return (
    <ModalWindow modalIsOpen={isOpen} closeModal={() => closeModalVideo(false)}>
      <div className={classes.wrap}>
        <div className={classes.playerWrap}>
          <ReactPlayer
            config={{ file: { attributes: { controlsList: "nodownload" } } }}
            onContextMenu={(e: any) => e.preventDefault()}
            url={data.link || isOpen.link}
            playing
            controls
            width="100%"
          />
          <div className={classes.panelFix}>
            <div className={classes.navPanel}>
              <div>
                <NameonChat text={`${data.name}, ${data.age}`} />
                <p style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={data.flag}
                    alt=""
                    width="15"
                    style={{ marginRight: "10px" }}
                    loading="lazy"
                  />
                  <NameonChat isSpan text={data.city} />
                </p>
              </div>
              <Box flex>
                <Button
                  onClick={handleChat}
                  icon="chat"
                  text="Start chatting"
                  className={classes.btn}
                />
                <ButtonWithDropdown
                  buttonText=""
                  icon="dots-triple"
                  sizeIcon={4}
                >
                  <>
                    <Button
                      onClick={handleLike}
                      disabled={isLikeGirl}
                      text="Like"
                      isWhite
                      borderOff
                      iconSvg={isLikeGirl ? "liked-darkblue" : "like-violet"}
                      color="#720066"
                      className={classes.btnDrop}
                    />
                    <Button
                      onClick={handleLetters}
                      text="Letter"
                      isWhite
                      borderOff
                      iconSvg="letter-violet"
                      color="#720066"
                      className={classes.btnDrop}
                    />
                    <Button
                      onClick={handleSendGift}
                      text="Gift"
                      isWhite
                      borderOff
                      iconSvg="gift-violet"
                      color="#720066"
                      className={classes.btnDrop}
                    />
                  </>
                </ButtonWithDropdown>
              </Box>
            </div>
          </div>
        </div>
      </div>
    </ModalWindow>
  );
};

export default ModalVideoPlayer;

import cn from "classnames";

// @ts-ignore
import classes from "./CheckboxTogle.module.scss";

interface IProps {
  value: string;
  onChange: (e: any) => void;
  checked: boolean;
  className?: string;
}

const CheckboxTogle = ({ value, onChange, checked, className }: IProps) => (
  <label className={cn(classes.box, className)}>
    <p className={classes.lable}>{value}</p>
    <input
      type="checkbox"
      value={value}
      onChange={onChange}
      checked={checked}
      className={classes.visually_hidden}
    />
    <span
      className={cn(classes.checkBox, checked && classes.checkBox_selected)}
    />
  </label>
);

export default CheckboxTogle;

import { useSelector } from "react-redux";
import { useEffect } from "react";

import { sendBinomoFromStore } from "utils/binomo";
import * as USER from "data/user";
import { sendAfficeFromStore } from "utils/affise";
import { useGoogleDataLayer, useFBConversion } from "hooks";
import {
  //  getRequest,
  postRequest,
} from "utils/requests";

export const useInitUtmSpend = () => {
  const userState = useSelector((state) => state?.auth?.user?.user_info);
  const balance = useSelector((state) => state?.auth?.myProfile?.user_detail);
  const tail = useSelector((state) => state?.auth?.tail?.response?.tail);
  const { pushEventSpendAllCredit } = useGoogleDataLayer();
  const { sentFBSpendAllCredits } = useFBConversion();

  useEffect(() => {
    const spendAllCreditStore = localStorage.getItem("spend_all_credit");
    // This applies only to bonuses - free credits
    if (
      balance?.credits + balance?.bonus_credits + balance?.promo_credits < 2 &&
      !spendAllCreditStore &&
      !!userState?.spend_first_credit &&
      !+userState?.payment_total
    ) {
      localStorage.setItem("spend_all_credit", "1");

      postRequest("/api/marketing/checkMarketingConversion", {
        eventName: "spend_all_credit",
      }).then((res) => {
        if (res?.status && res?.response?.check) {
          if (userState) {
            sendBinomoFromStore({
              externalID: userState?.external_id,
              siteID: userState?.site_id,
              status: USER.BINOMO_SPEND_ALL_CREDITS,
              clickid: tail?.response?.clickid,
              utm_aff_hash: tail?.utm_aff_hash,
              tail: tail?.response?.tail,
              info: userState,
            });

            sendAfficeFromStore({
              status: USER.AFFISE_SPEND_ALL_CREDITS,
              info: userState,
            });
          }

          if (tail?.response?.tail?.includes("utm_age=45")) {
            sentFBSpendAllCredits();
          }

          // Push DataLayer for FB
          pushEventSpendAllCredit();
        }
      });
    }

    // eslint-disable-next-line
  }, [
    userState,
    balance?.credits,
    balance?.bonus_credits,
    balance?.promo_credits,
  ]);
};

import * as Sentry from "@sentry/react";
import { getApiInstance } from "./apiClient";
import { isSentryInitialized } from "utils/sentryInit";

export const getRequest = (url: string) => {
  const api = getApiInstance();

  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: Error) => {
        if (isSentryInitialized) {
          Sentry.captureException(err);
        }
        return reject(err);
      });
  });
};

export const postRequest = (url: string, REQ_BODY: any) => {
  const api = getApiInstance();

  return new Promise((resolve, reject) => {
    api
      .post(url, REQ_BODY)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: Error) => {
        if (isSentryInitialized) {
          Sentry.captureException(err);
        }
        return reject(err);
      });
  });
};

export const deleteRequest = (url: string, REQ_BODY: any) => {
  const api = getApiInstance();

  return new Promise((resolve, reject) => {
    api
      .delete(url, { data: REQ_BODY })
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: Error) => {
        if (isSentryInitialized) {
          Sentry.captureException(err);
        }
        return reject(err);
      });
  });
};

export const putRequest = (url: string, REQ_BODY: any) => {
  const api = getApiInstance();

  return new Promise((resolve, reject) => {
    api
      .put(url, REQ_BODY)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: Error) => {
        if (isSentryInitialized) {
          Sentry.captureException(err);
        }
        return reject(err);
      });
  });
};

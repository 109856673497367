import cn from "classnames";

// @ts-ignore
import classes from "./Counter.module.scss";

interface IProps {
  classCounter?: string;
  counter: number | undefined;
  bigMode?: boolean;
}

const Counter = ({ classCounter, counter, bigMode }: IProps) => {
  return (
    <>
      {counter !== 0 && counter !== undefined ? (
        <p
          className={cn(
            classes.counter,
            bigMode && classes.counter_big,
            classCounter
          )}
        >
          {counter}
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default Counter;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";

export const useWebWidget = () => {
  const userState = useSelector(selectUser)?.user_info;

  useEffect(() => {
    if (userState?.external_id) {
      try {
        // eslint-disable-next-line
        zE(() => {
          // eslint-disable-next-line
          zE("webWidget", "identify", {
            name: `${userState?.name}, ${userState?.age} (${userState?.external_id})`,
            email: userState?.email,
          });
          // eslint-disable-next-line
          zE("webWidget", "prefill", {
            name: { value: userState?.name, readOnly: true },
            email: { value: userState?.email, readOnly: true },
          });
        });
      } catch (e) {
        console.warn(e);
      }
    }
  }, [userState]);
};

import { Box } from "components/atomsKit/Elements";
import { NameonChat } from "components/designAtom/Elements";

// import cn from "classnames";

// @ts-ignore
import classes from "./StickersGroup.module.scss";

interface IProps {
  className?: string;
  stickersConfig: any;
  onChangeStiker: (name: string) => void;
}

const StickersGroup = ({
  stickersConfig,
  onChangeStiker,
  className,
}: IProps) => {
  return (
    <ul className={classes.wrapTypeSticker}>
      {stickersConfig.map((stickerGroup: any) => (
        <li>
          <Box flex align={"center"} justify={"between"}>
            <NameonChat text={stickerGroup.title} />
          </Box>
          <ul className={classes.wrapStikersGroup}>
            {stickerGroup.stickers.map((stick: any) => (
              <li>
                <button
                  onClick={() => {
                    onChangeStiker(stick.name);
                  }}
                >
                  <img
                    src={stick.img}
                    alt=""
                    className={classes.sticker}
                    loading="lazy"
                  />
                </button>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  );
};

export default StickersGroup;

import cn from "classnames";

// @ts-ignore
import classes from "./Timeonchat.module.scss";

interface IProps {
  className?: string;
  isSpan?: boolean;
  isHeader?: boolean;
  text: string | number;
  size?: number;
  margin?: string;
}

const Timeonchat = ({
  isSpan,
  isHeader,
  text,
  size,
  margin,
  className,
}: IProps) => {
  return (
    <>
      {isSpan ? (
        <span
          className={cn(classes.text, className && className)}
          style={{ fontSize: `${size}px`, margin: margin }}
        >
          {text}
        </span>
      ) : isHeader ? (
        <h2
          className={cn(classes.text, className && className)}
          style={{ fontSize: `${size}px`, margin: margin }}
        >
          {text}
        </h2>
      ) : (
        <p
          className={cn(classes.text, className && className)}
          style={{ fontSize: `${size}px`, margin: margin }}
        >
          {text}
        </p>
      )}
    </>
  );
};

export default Timeonchat;

// import { Button } from "components/atomsKit/Elements";
// import { Image } from "components/designAtom/Elements";

import cn from "classnames";

// @ts-ignore
import classes from "./ButtonGift.module.scss";

interface IProps {
  text: string;
  isSelected: boolean;
  onClick?: any;
}

const ButtonGift = ({ text, isSelected, onClick }: IProps) => {
  return (
    <button
      className={cn(classes.btn, isSelected && classes.btn_isSelected)}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default ButtonGift;

// @ts-ignore
import classes from "./SendWinkBar.module.scss";

interface IProps {
  config?: { message_content: string; id: number; site_id: number }[];
  handleWink: (wink: any) => void;
}

const SendWinkBar = ({ config, handleWink }: IProps) => {
  return (
    <ul className={classes.wrap}>
      {config?.map((item) => (
        <li key={item.id}>
          <button
            className={classes.btn}
            onClick={() => {
              handleWink(item);
            }}
          >
            {item.message_content}
          </button>
        </li>
      ))}
    </ul>
  );
};

export default SendWinkBar;

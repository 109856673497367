import {
  SearchList,
  ModalFilteModern,
  ModalFilter,
  FilterBody,
  FilterBodyGender,
  ButtonIcon,
  PageWrap,
  Box,
  Button,
  ModalVideoPlayer,
  ModalContent,
} from "components/atomsKit/Elements";
import { Image, NameonChat, Textonchat } from "components/designAtom/Elements";

import BanersSubPage from "pages/BanersSubPage/BanersSubPage";

import useSearchPage from "./useSearchPage";

import { parserFilterOptions, sortCardsToFreeFirst } from "utils/common";
import { images } from "utils/images";

// @ts-ignore
import classes from "./SearchPage.module.scss";

const SearchPage = () => {
  const {
    cards,
    handleProfile,
    isOpenFilter,
    modalFilterClose,
    filtersOptions,
    handleLike,
    onChangeOption,
    onInputChangeCity,
    citySerch,
    cityOptions,
    valuesFilter,
    handleScroll,
    isNotDefaultFilter,
    handleClearFilters,
    handleChat,
    handlePlayVideo,
    isOpenModalVideo,
    closeModalVideo,
    dataForVideo,
    handlCardPhoto,
    isOpenModalPhoto,
    handleCloseModalPhoto,
    dataForModalPhoto,
    sayHalloList,
    handleWink,
    setIsOpenModalWink,
    isOpenModalWink,
    handleSayHello,
    search_multigender,
  } = useSearchPage();

  return (
    <>
      {isOpenModalPhoto && (
        <ModalContent
          modalIsOpen={isOpenModalPhoto}
          closeModal={handleCloseModalPhoto}
          counterPrivatPhoto={
            dataForModalPhoto?.item.filter(
              (image) => +image?.free === 0 && !image?.payed
            )?.length
          }
          items={images(sortCardsToFreeFirst(dataForModalPhoto?.item))}
          id={dataForModalPhoto?.external_id}
          chat_uid={dataForModalPhoto?.chat_uid}
          wink={dataForModalPhoto.wink}
          handleChat={handleChat}
          handleSayHello={handleSayHello}
          handleWink={handleWink}
          sayHalloList={sayHalloList}
          isOpenModalWink={isOpenModalWink}
          setIsOpenModalWink={setIsOpenModalWink}
          handleProfile={handleProfile}
        />
      )}

      {search_multigender ? (
        <ModalFilteModern
          modalIsOpen={isOpenFilter}
          closeModal={modalFilterClose}
        >
          <FilterBodyGender
            filtersOptions={parserFilterOptions(filtersOptions)}
            onChangeOption={onChangeOption}
            valuesFilter={valuesFilter}
          />
        </ModalFilteModern>
      ) : (
        <ModalFilter modalIsOpen={isOpenFilter} closeModal={modalFilterClose}>
          <FilterBody
            filtersOptions={parserFilterOptions(filtersOptions)}
            cityOptions={cityOptions}
            onChangeOption={onChangeOption}
            valuesFilter={valuesFilter}
            onInputChangeCity={onInputChangeCity}
            citySerch={citySerch}
          />
        </ModalFilter>
      )}

      {dataForVideo && (
        <ModalVideoPlayer
          isOpen={isOpenModalVideo}
          data={dataForVideo}
          closeModalVideo={closeModalVideo}
        />
      )}
      {!isNotDefaultFilter && (
        <div className={classes.btnClearFilter}>
          <ButtonIcon
            onClick={() => handleClearFilters()}
            icon={"ok"}
            color={"white"}
            text={"Clear"}
            className={classes.clear}
          />
          <div className={classes.marker} />
        </div>
      )}
      {cards?.length > 0 ? (
        <PageWrap height100>
          <div className={classes.baners}>
            <BanersSubPage />
          </div>
          <div className={classes.pageContent}>
            <SearchList
              handlCardPhoto={handlCardPhoto}
              cardsList={cards}
              handleProfile={handleProfile}
              handleLike={handleLike}
              handleScroll={handleScroll}
              handleChat={handleChat}
              handlePlayVideo={handlePlayVideo}
            />
          </div>
        </PageWrap>
      ) : (
        <PageWrap height100>
          <Box
            flex
            align="center"
            justify="center"
            directionColumn
            height100
            padding={"0 16px"}
          >
            <Image name="sweetheart_image" className={classes.emptyImg} />
            <NameonChat
              margin="20px 0 0 0"
              size={18}
              text={"No results found based on filters you applied"}
              textAlign={"center"}
            />
            <Textonchat
              textAlign={"center"}
              margin="20px 0 20px 0"
              text={
                "Please clear or change filter parameters to see relevant results"
              }
            />
            <Button
              fitContent
              padding="0 16px 0 16px"
              onClick={handleClearFilters}
              isWhite
              text="Clear all filters"
            />
          </Box>
        </PageWrap>
      )}
    </>
  );
};

export default SearchPage;

import { useDispatch, useSelector } from "react-redux";

import {
  useTransition,
  useGoogleDataLayer,
  useFBConversion,
  useAwinConversion,
} from "hooks";

import * as USER from "data/user";

import { getUserInfo } from "redux/auth/operations";

import { sendBinomoFromStore } from "utils/binomo";
import { sendAfficeFromStore } from "utils/affise";

import { togleGlobalModal } from "redux/interface/slice";
import { postRequest } from "utils/requests";

export const useSuccessPaymentCallback = () => {
  const dispatch = useDispatch();
  const transition = useTransition();
  const {
    pushEventUniversalPurchase,
    pushEventUniversalPurchase1,
    pushEventUniversalPurchaseOS,
    pushEventUniqueUniversalPurchase,
    pushEventFirstPurchase,
    pushEventPurchase,
    pushEventFirstPurchaseValue,
    pushEventFirstPurchaseTargetOs,
    pushEventSecondPurchase,
    pushEventThirdPurchaseValue,
    pushEventEcommerce,
    pushEventFirstPurchaseOs,
    pushEventSecondPurchaseOs,
    pushEventThirdPurchaseOs,
  } = useGoogleDataLayer();
  const { sentFBFirstPurchase, sentFBTwoMorePurchase, sentFBEachPurchase } =
    useFBConversion();
  const { sendAwinFirstSale } = useAwinConversion();

  const userState = useSelector((state) => state?.auth?.user?.user_info);
  const tail = useSelector((state) => state?.auth?.tail?.response?.tail);

  const initTail = (credits, count) => {
    const tail = `?utm_userid=${
      userState?.external_id
    }&utm_credits=${credits}&utm_packages=${+count === 1 ? "first" : "again"}`;

    transition.addTail(tail, true);
  };

  const initAlert = (credits) => {
    dispatch(
      togleGlobalModal({
        isOpen: "openPaySucces",
        description: credits,
      })
    );
  };

  const initMarketingStatistic = (count, price, isRouting = false) => {
    if (!userState?.external_id) return;
    const isActiveTab = isRouting ? true : !document.hidden;

    pushEventEcommerce();

    if (count >= 1 && count <= 3) {
      sendBinomoFromStore({
        externalID: userState?.external_id,
        siteID: userState?.site_id,
        status: USER.BINOMO_BUY_CREDIT_LIST[+count - 1],
        clickid: tail?.response?.clickid,
        utm_aff_hash: tail?.utm_aff_hash,
        tail: tail?.response?.tail,
      });

      sendAfficeFromStore({
        status: USER.AFFISE_BUY_CREDIT_LIST[+count - 1],
        info: userState,
      });
    }

    if (+count >= 1 && isActiveTab) {
      pushEventUniqueUniversalPurchase({ price, count });
      pushEventUniversalPurchaseOS({ price, count });
      sentFBEachPurchase(price);
    }

    if (+count > 1 && isActiveTab) {
      //GT-2199
      postRequest("/api/marketing/checkMarketingConversion", {
        eventName: "universal_purchase_2",
      }).then((res) => {
        if (res?.status && !res?.response?.check) {
          pushEventUniversalPurchase({ price, count });
          pushEventUniversalPurchase1({ price, count });
        }
      });
    }

    if (+count === 1 && isActiveTab) {
      pushEventUniversalPurchase({ price, count });
      pushEventUniversalPurchase1({ price, count });
      pushEventPurchase({ count }); // Push DataLayer for FB
      pushEventFirstPurchase();
      pushEventFirstPurchaseValue({ count });
      pushEventFirstPurchaseTargetOs({ count });
      pushEventFirstPurchaseOs();

      sendAwinFirstSale();

      postRequest("/api/marketing/checkMarketingConversion", {
        eventName: "universal_purchase",
      }).then((res) => {
        if (res?.status && res?.response?.check) {
          if (tail?.response?.tail?.includes("utm_age=45")) {
            sentFBFirstPurchase();
          }
        }
      });
    } else if (+count === 2 && isActiveTab) {
      if (tail?.response?.tail?.includes("utm_age=45")) {
        sentFBTwoMorePurchase();
      }

      pushEventSecondPurchase();
      pushEventSecondPurchaseOs();
    }

    if (+count === 3 && isActiveTab) {
      pushEventThirdPurchaseValue({ price, count });
      pushEventThirdPurchaseOs();
    }
  };

  const closeBuyingModal = () => {
    dispatch(getUserInfo());
  };

  return ({ credits, count, price, isRouting = false }) => {
    if (!isRouting) {
      initAlert(credits);
      closeBuyingModal();
    }

    initMarketingStatistic(count, price, isRouting);
    initTail(credits, count);
  };
};

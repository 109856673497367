import { useEffect } from "react";

import {
  PageWrap,
  Box,
  // Loader
} from "components/atomsKit/Elements";
import { Image, Textonchat } from "components/designAtom/Elements";

import usePaymentPage from "./usePaymentPage";

import { useHandleRouting } from "hooks";

// @ts-ignore
import classes from "./PaymentPage.module.scss";

const PaymentPage = () => {
  const { user_detail } = usePaymentPage() as any;

  const { launch } = useHandleRouting();

  useEffect(() => {
    launch();
    // eslint-disable-next-line
  }, []);

  return (
    <PageWrap scroll height100>
      <>
        <Box flex align="center" justify="center" marginTop="12px">
          <Textonchat
            className={classes.balansText}
            text={"Your balance"}
            margin="0 10px 0 0"
            size={12}
          />
          <Image name="your-balance" />
          <Textonchat
            className={classes.creditText}
            text={user_detail?.credits + user_detail?.bonus_credits}
            margin="0 0 0 10px"
            size={20}
          />
        </Box>
        {/* <Loader isLoading={isLoading} /> */}
      </>
    </PageWrap>
  );
};

export default PaymentPage;

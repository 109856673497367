export const text = {
  CONNECT_WITH_NEW_PEOPLE: "Connect with new people with similar interests. ",
  CONNECT_WITH_NEW_PEOPLE_AvoDate:
    "Engage in conversations that stir genuine emotions, bonding over common interests",
  CONNECT_WITH_NEW_PEOPLE_Okamour:
    "Engage in conversations that stir genuine emotions, bonding over common interests",
  CONNECT_WITH_NEW_PEOPLE_DESCRIPTION:
    "You’re searching - we can help. Register for free! Choose among a variety of premium profiles and use plenty of fascinating services!",
  CONNECT_WITH_NEW_PEOPLE_DESCRIPTION_AvoDate:
    "Become a member of our friendly family and see how efficient our services are. We will be by your side whenever you need professional assistance!",
  CONNECT_WITH_NEW_PEOPLE_DESCRIPTION_Okamour:
    "Sign up for free and see that we offer the best services to help you connect with like-minded people.",
  ALREADY_HAVE: "Already have an account?",
  FREE_TODAY: "Register for free today",
  SOFIYA_POLICY:
    "By clicking “Find Your Matches” you agree with the Terms and Conditions,Privacy Policy andCookies Policy,Refund Policy,Community Standards. You also agree to receive any emails relating to SofiaDate.com",
  AGREE:
    "By clicking “Find Your Matches” you confirm that you are over the age of 18 and you agree with the Terms and Conditions, Privacy Policy, Cookies Policy, Refund Policy and Community Standards. You also agree to receive any emails relating to SofiaDate.com",
  MEMBER_PROFILE:
    "All members are either single or divorced, with presence of children indicated. According to our statistics, there are 2-3 times more female members than men who are looking for meaningful communication or a serious relationship. That is why they start communicating first, so you don’t have to fear making the first step!",
  COPIRIGHT:
    "© 2019 my web site. Created and powered by Advanced GrouP. all rights reserved",
  CONTACT_MODAL_TEXT:
    "A contact request is made so that you can receive the member's e-mail and start communicating with her outside of our platform. We care about the privacy of our users. Therefore, you should chat with your partner for a while and establish a comfortable relationship before requesting contact details. Once you get to know each other better and are confident that your relationship is building, your partner will be more likely to agree to this request.",
  MEETENG_MODAL_TEXT_1:
    "After receiving a request, your conversational partner has 72 hours to confirm or reject a meeting request.",
  MEETENG_MODAL_TEXT_2:
    "You can organize a meeting absolutely independently or contact our partners, who will help you to set it up. They will do everything possible to make your meeting flow perfectly so that you will remember it for a long time!",
  MEETENG_MODAL_TEXT_3:
    "Please keep in mind that to make a meeting request, you must first get the member’s contact details through the “Contact Request” Service.",
};

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { selectedGifts, giftFor } from "redux/interface/selectors";
import { delGift } from "redux/interface/slice";
import { togleGlobalModal } from "redux/interface/slice";

import { getRequest, postRequest } from "utils/requests";

const useLettersPage = () => {
  const [message, setMessage] = useState("");
  const [specialText, setSpecialText] = useState("");

  const dispatch = useDispatch();
  const cart = useSelector(selectedGifts);
  const profile = useSelector(giftFor);

  const handeDelOption = (id) => {
    dispatch(delGift(id));
  };

  const onChangeMessage = (e) => {
    const value = e.target.value;
    if (value.length <= 300) {
      setMessage(value);
    }
  };

  const onChangeSpecialText = (e) => {
    const value = e.target.value;
    if (value.length <= 300) {
      setSpecialText(value);
    }
  };

  const handleSendGift = () => {
    getRequest("/api/user/info").then((res) => {
      if (res?.fingerprint) {
        localStorage.setItem("fingerprinthash", res?.fingerprint);
      }
      const totalPrice = cart.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.price,
        0
      );

      const credits = res?.user_info?.credits;
      if (totalPrice > credits) {
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: "You don’t have enough credit",
            description: `You need ${
              totalPrice - credits
            } credits more to buy this gift`,
            btnText: "Got it",
          })
        );
      } else {
        cart.forEach((item) => {
          const REQ_BODY = {
            comment: specialText,
            female_id: profile.id,
            message: message,
            product_id: item.id,
          };

          postRequest("/api/shop/addorder", REQ_BODY).then(() => {
            dispatch(delGift(item.id));
          });
        });
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: "Gift bought",
            description:
              "Your gift will be presented in 10 days. Once the gift is handed in, you will receive a message from our moderation team.  The delivery of the gift can be cancelled only in 24 hours from the moment you made this order.",
            btnText: "Got it",
          })
        );
      }
    });
  };

  return {
    cart,
    handeDelOption,
    message,
    onChangeMessage,
    specialText,
    onChangeSpecialText,
    handleSendGift,
    profile,
  };
};

export default useLettersPage;

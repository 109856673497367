import rotateImg from "img/rotate.png";

const RotateMessage = () => {
  const containerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "black",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  };

  return (
    <div style={containerStyle}>
      <img src={rotateImg} alt="" width="200px" />
      <p style={{ padding: "20px 0 0 0", fontSize: "18px" }}>
        Rotate your smartphone for a better experience
      </p>
    </div>
  );
};

export default RotateMessage;

const injectedOptions = {
  isAnonymousFrame: {
    func: function isAnonymousFrame(frame) {
      return frame?.filename === "<anonymous>";
    },
    descriptionForDeveloper: "Checks if the first frame is anonymous.",
  },

  isLocalFileError: {
    func: function isLocalFileError(frame) {
      const filename = frame?.filename || "";
      return (
        filename === window.location.pathname ||
        (filename.startsWith(window.location.origin) &&
          !filename.includes("/static/"))
      );
    },
    descriptionForDeveloper:
      "Checks if the last frame belongs to a local file.",
  },

  isExternalScriptError: {
    func: function isExternalScriptError(frames) {
      const externalScripts = [
        "https://www.googletagmanager.com",
        "https://googleads.",
        "https://sgtm.",
      ];
      return frames.some((frame) =>
        externalScripts.some((url) => frame?.filename?.startsWith(url))
      );
    },
    descriptionForDeveloper:
      "Checks if the error was triggered by external scripts.",
  },

  isSpecificErrorMessage: {
    func: function isSpecificErrorMessage(event) {
      const errorMessage = event?.exception?.values?.[0]?.value || "";
      return errorMessage.includes(
        "null is not an object (evaluating '/utm_pod=([^&]*)/g.exec(document.URL)[1]')"
      );
    },
    descriptionForDeveloper: "Checks if the error matches a specific message.",
  },
};

export function isInjectedCode(event) {
  const frames = event?.exception?.values?.[0]?.stacktrace?.frames;
  if (!frames?.length) return false;

  const [firstFrame, lastFrame] = [frames[0], frames[frames.length - 1]];

  return (
    injectedOptions["isAnonymousFrame"].func(firstFrame) ||
    injectedOptions["isLocalFileError"].func(lastFrame) ||
    injectedOptions["isExternalScriptError"].func(frames) ||
    injectedOptions["isSpecificErrorMessage"].func(event)
  );
}

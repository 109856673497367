import { createAsyncThunk } from "@reduxjs/toolkit";
import { postRequest } from "utils/requests";

export const fetchLetters = createAsyncThunk(
  "letters/fetchletters",
  async (REQ_BODY, thunkAPI) => {
    try {
      const response = await postRequest("/api/mailbox/mails", REQ_BODY);
      return { res: response.response?.mails, page: REQ_BODY.page };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchLettersUser = createAsyncThunk(
  "letters/fetchlettersuser",
  async (REQ_BODY, thunkAPI) => {
    try {
      const response = await postRequest("/api/mailbox/mails", REQ_BODY);

      return REQ_BODY.page > response.response?.pages ? [] : response.response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const delCard = createAsyncThunk(
  "letters/delcard",
  async (chat_id, thunkAPI) => {
    try {
      await postRequest("/api/mailbox/trashThread", {
        chat_id: chat_id,
      });

      return chat_id;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const setbookmark = createAsyncThunk(
  "letters/setbookmark",
  async (REQ_BODY, thunkAPI) => {
    try {
      const response = await postRequest("/api/mailbox/setbookmark", REQ_BODY);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const unsetbookmark = createAsyncThunk(
  "letters/unsetbookmark",
  async (REQ_BODY, thunkAPI) => {
    try {
      const response = await postRequest(
        "/api/mailbox/unsetbookmark",
        REQ_BODY
      );
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const trashmail = createAsyncThunk(
  "letters/trashmail",
  async (REQ_BODY, thunkAPI) => {
    try {
      const response = await postRequest("/api/mailbox/trashmail", REQ_BODY);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const setFavorite = createAsyncThunk(
  "letters/setfavorite",
  async (req_body, thunkAPI) => {
    try {
      await postRequest("/api/chat/setFavorite", {
        chat_uid: req_body.chat_uid,
        favorite: req_body.favorite,
        user_id: req_body.user_id,
      });
      return req_body.favorite;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

import {
  SelectDropDown,
  CheckboxTogle,
  Input,
} from "components/atomsKit/Elements";

import cn from "classnames";

import { ageOptions } from "utils/common";

// @ts-ignore
import classes from "./FilterBody.module.scss";

interface IProps {
  filtersOptions: any;
  onChangeOption: any;
  valuesFilter: any;
  onInputChangeCity: any;
  citySerch: any;
  cityOptions: any;
}

const FilterBody = ({
  filtersOptions,
  onChangeOption,
  valuesFilter,
  onInputChangeCity,
  citySerch,
  cityOptions,
}: IProps) => {
  return (
    <section className={classes.wrap}>
      <div className={classes.ageWrap}>
        <p className={classes.lable}>Age range</p>
        <SelectDropDown
          placeholder={"From"}
          options={ageOptions()}
          value={valuesFilter.from}
          onChange={(value: any) =>
            onChangeOption("from", { ...value, id: value.value })
          }
          className={classes.ageDrop}
        />
        <SelectDropDown
          placeholder={"To"}
          options={ageOptions()}
          value={valuesFilter.to}
          onChange={(value: any) =>
            onChangeOption("to", { ...value, id: value.value })
          }
          className={classes.ageDrop}
        />
      </div>
      <CheckboxTogle
        value="ONLINE"
        onChange={() => onChangeOption("online", !valuesFilter.online)}
        checked={valuesFilter.online}
        className={classes.onlineWrap}
      />
      <Input
        icon=""
        onChange={(value) => {
          onChangeOption("id", value.target.value);
        }}
        value={valuesFilter.id}
        placeholder="Search ID or name"
        className={classes.selectWrap}
      />
      <SelectDropDown
        isSearchable
        inputValue={valuesFilter?.country?.name}
        placeholder={"Choose country"}
        options={filtersOptions.country_list}
        value={valuesFilter.country}
        onChange={(value: any) => onChangeOption("country", value)}
        className={classes.selectWrap}
        label={"COUNTRY"}
        isClearable
      />

      <SelectDropDown
        isSearchable
        inputValue={citySerch}
        onInputChange={onInputChangeCity}
        placeholder={"Choose city"}
        options={cityOptions}
        value={valuesFilter.city}
        onChange={(value: any) => onChangeOption("city", value)}
        className={classes.selectWrap}
        label={"CITY"}
        isClearable
      />

      <p className={cn(classes.lable, classes.lable_margin)}>HEIGHT RANGE</p>
      <div className={classes.ageWrap}>
        <SelectDropDown
          placeholder={"From"}
          options={filtersOptions.height_list}
          value={valuesFilter.heightfrom}
          onChange={(value: any) => onChangeOption("heightfrom", value)}
          className={classes.ageDrop}
        />
        <SelectDropDown
          placeholder={"To"}
          options={filtersOptions.height_list}
          value={valuesFilter.heightto}
          onChange={(value: any) => onChangeOption("heightto", value)}
          className={classes.ageDrop}
        />
      </div>
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.body_list}
        value={valuesFilter.bodytype}
        onChange={(value: any) => onChangeOption("bodytype", value)}
        className={classes.selectWrap}
        label={"BODY TYPE"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.eye_list}
        value={valuesFilter.eye}
        onChange={(value: any) => onChangeOption("eye", value)}
        className={classes.selectWrap}
        label={"EYE COLOR"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.occupation_list}
        value={valuesFilter.job}
        onChange={(value: any) => onChangeOption("job", value)}
        className={classes.selectWrap}
        label={"JOB OR PROFESSION"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.hair_list}
        value={valuesFilter.haircolor}
        onChange={(value: any) => onChangeOption("haircolor", value)}
        className={classes.selectWrap}
        label={"HAIR COLOR"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.marital_list}
        value={valuesFilter.marital}
        onChange={(value: any) => onChangeOption("marital", value)}
        className={classes.selectWrap}
        label={"MARITAL STATUS"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.education_list}
        value={valuesFilter.education}
        onChange={(value: any) => onChangeOption("education", value)}
        className={classes.selectWrap}
        label={"EDUCATION"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.reason_list}
        value={valuesFilter.purpose}
        onChange={(value: any) => onChangeOption("purpose", value)}
        className={classes.selectWrap}
        label={"PURPOSE OF STAYING ON THE SITE"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.religion_list}
        value={valuesFilter.religion}
        onChange={(value: any) => onChangeOption("religion", value)}
        className={classes.selectWrap}
        label={"RELIGION"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.children_list}
        value={valuesFilter.children}
        onChange={(value: any) => onChangeOption("children", value)}
        className={classes.selectWrap}
        label={"NUMBER OF CHILDREN"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.morechildren_list}
        value={valuesFilter.whantchildren}
        onChange={(value: any) => onChangeOption("whantchildren", value)}
        className={classes.selectWrap}
        label={"WHANT TO HAVE MORE CHILDREN"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.alcohol_list}
        value={valuesFilter.alcohol}
        onChange={(value: any) => onChangeOption("alcohol", value)}
        className={classes.selectWrap}
        label={"ALCOHOL"}
        isClearable
      />
      <SelectDropDown
        placeholder={"Choose option"}
        options={filtersOptions.smoke_list}
        value={valuesFilter.smoking}
        onChange={(value: any) => onChangeOption("smoking", value)}
        className={classes.selectWrap}
        label={"SMOKING"}
        isClearable
      />
      <p className={cn(classes.lable, classes.fixBottom)}>
        All filters are applied automatically
      </p>
    </section>
  );
};

export default FilterBody;

import { ChatCard } from "components/atomsKit/Elements";

import {
  formatDateTime,
  renderLastMessage,
  cutTextforToltip,
} from "utils/common";

// @ts-ignore
import classes from "./ChatList.module.scss";

interface IProps {
  className?: string;
  handleDelCardChat?: (chat_uid: string) => void;
  scrollableTarget: string;
  chatList: {
    chat_uid: string;
    isFavorite: boolean;
    isOnline: boolean;
    public_external_id: number;
    external_id: number;
    isNew: boolean;
    is_male: number;
    name: string;
    age: number;
    message: string;
    message_type: string;
    date_created: string;
    read_status: number;
    womanAvatars: {
      avatar: string;
      avatar_title: string;
      external_id: number;
      search_img: string;
    };
  }[];
  handleCard: (chat_uid: string) => void;
}

const ChatList = ({
  chatList,
  handleCard,
  handleDelCardChat,
  scrollableTarget,
}: IProps) => {
  return (
    <ul className={classes.wrap} id={scrollableTarget}>
      {chatList.map((item) => (
        <li key={item.chat_uid}>
          <ChatCard
            key={item.public_external_id}
            className={classes.card}
            chat_uid={item.chat_uid}
            isFavorite={item.isFavorite}
            isOnline={item.isOnline}
            handleCard={handleCard}
            handleDelCard={handleDelCardChat}
            external_id={item.external_id}
            isNew={item.isNew}
            name={item.name}
            age={item.age}
            message={`${!!+item.is_male ? "You: " : ""}${renderLastMessage(
              item.message_type,
              item.message ? cutTextforToltip(item.message, 62) : "",
              !item.is_male,
              item.name
            )}`}
            time={
              item.date_created ? formatDateTime(item?.date_created) : undefined
            }
            imgLink={item.womanAvatars?.avatar}
            isRead={!!item?.is_male ? !!item.read_status : undefined}
          />
        </li>
      ))}
    </ul>
  );
};

export default ChatList;

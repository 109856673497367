import { useState } from "react";

import {
  Box,
  Button,
  Stick,
  SendWinkModal,
} from "components/atomsKit/Elements";
// import { Image } from "components/designAtom/Elements";

import cn from "classnames";

import { images as imagesMark } from "utils/images";

import { sortCardsToFreeFirst } from "utils/common";

// @ts-ignore
import classes from "./CardOnline.module.scss";

interface IProps {
  info: any;
  handleProfile: (id: number) => void;
  handleChat: (e: string, id: number) => void;
  handleLike: (external_id: number) => void;
  handlePlayVideo: (external_id: number) => void;
  handlCardPhoto: any;
  isVideoList?: boolean;
  handleScroll: any;
  handleLetters: (external_id: number) => void;
  screenVideoImg?: (external_id: number) => void;
  handleSayHello: any;
  sayHalloList: any;
  handleWink: any;
  isOpenModalWink: any;
  setIsOpenModalWink: any;
}

const CardOnline = ({
  info,
  handleProfile,
  handleChat,
  handleLike,
  handlCardPhoto,
  handleLetters,
  sayHalloList,
  handleWink,
}: IProps) => {
  const { avatar_title, name, age, images } = info;

  const [isOpenModalWink, setIsOpenModalWink] = useState(false);

  return (
    <>
      {isOpenModalWink && (
        <SendWinkModal
          config={sayHalloList}
          handleWink={handleWink}
          handleChat={() => {
            handleChat(info.chat_uid, info.external_id);
          }}
          modalIsOpen={isOpenModalWink}
          closeModal={() => {
            setIsOpenModalWink(false);
          }}
          recipient_id={info.external_id}
        />
      )}

      <Box className={classes.wrap}>
        <Stick text="I'm online now" className={classes.onLine} />
        <Box flex align="center" width100>
          <Box className={classes.avatarWrap}>
            <img
              alt=""
              src={avatar_title}
              className={classes.photo}
              onClick={() => {
                handleProfile(info.external_id);
              }}
            />
          </Box>
          <Box marginLeft="8px" width100>
            <p
              className={classes.name}
              onClick={() => {
                handleProfile(info.external_id);
              }}
            >
              {name}, {age}
            </p>
            <Box flex marginTop="8px" width100>
              <Button
                text="Chat Now"
                onClick={() => handleChat(info.chat_uid, info.external_id)}
              />
              <Button
                text={!!info.liked ? "Liked" : "Like"}
                disabled={!!info.liked}
                isWhite
                margin="0 0 0 6px"
                onClick={() => {
                  handleLike(info.external_id);
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box className={classes.sliderWrap}>
          {imagesMark(sortCardsToFreeFirst(images))?.map((item: any) => (
            <Box className={classes.imgWrap}>
              <img
                alt=""
                onClick={() => handlCardPhoto(info.chat_uid, info.external_id)}
                src={item.thumbnail}
                className={cn(classes.sliderImg, !item.free && classes.blure)}
              />
            </Box>
          ))}
        </Box>
        <Box flex marginTop="14px">
          {info.wink !== 1 && (
            <Button
              onClick={() => setIsOpenModalWink(true)}
              text="Say “Hello”"
              iconSvg="say-hello-yellow"
              isWhite
              className={classes.btnSayHello}
            />
          )}

          <Button
            text="Write letter"
            icon="mail"
            isWhite
            margin="0 0 0 6px"
            onClick={() => {
              handleLetters(info.public_external_id);
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default CardOnline;

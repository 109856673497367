import { getCookie } from "utils/common";
import { useSelector } from "react-redux";
import { selectUser } from "redux/auth/selectors";
import { postRequest } from "utils/requests";

export const useFBConversion = () => {
  const userState = useSelector(selectUser);
  const tail = useSelector((state) => state?.auth?.tail?.response?.tail);
  const isFBConversion = useSelector((state) => state?.auth);

  const hasUtmSourceFacebook = tail?.includes("utm_source=facebook");

  const fbp = getCookie("_fbp") || "";
  const fbc = getCookie("_fbc") || "";
  const REQ_BODY = {
    email: userState?.user_info?.email,
    external_id: userState?.user_info?.external_id,
    name: userState?.user_info?.name,
    b_year: userState?.user_info?.b_year,
    b_month: userState?.user_info?.b_month,
    b_day: userState?.user_info?.b_day,
    fbp: fbp,
    fbc: fbc,
  };

  const sentFBAuth = () => {
    if (hasUtmSourceFacebook && isFBConversion && userState?.user_info?.email) {
      postRequest("marketing/auth_conversion", REQ_BODY);
    }
  };

  const sentFBAuth55 = () => {
    if (hasUtmSourceFacebook && isFBConversion && userState?.user_info?.email) {
      postRequest("/api/marketing/auth_conversion_55", REQ_BODY);
    }
  };

  const sentFBDelfinConversion = () => {
    if (hasUtmSourceFacebook && isFBConversion && userState?.user_info?.email) {
      postRequest("/api/marketing/delfin_conversion", REQ_BODY);
    }
  };

  const sentFBConfirmEmailConversion = () => {
    if (hasUtmSourceFacebook && isFBConversion && userState?.user_info?.email) {
      postRequest("/api/marketing/confirm_email_conversion", REQ_BODY);
    }
  };

  const sentFBFirstPurchase = () => {
    if (hasUtmSourceFacebook && isFBConversion && userState?.user_info?.email) {
      postRequest("/api/marketing/payment_conversion", REQ_BODY);
    }
  };

  const sentFBTwoMorePurchase = () => {
    if (hasUtmSourceFacebook && isFBConversion && userState?.user_info?.email) {
      postRequest("/api/marketing/two_more_purchase_conversion", REQ_BODY);
    }
  };

  const sentFBSpendAllCredits = () => {
    if (hasUtmSourceFacebook && isFBConversion && userState?.user_info?.email) {
      postRequest("/api/marketing/spend_all_credits_conversion", REQ_BODY);
    }
  };

  const sentFBEachPurchase = (price) => {
    if (!hasUtmSourceFacebook || !userState?.user_info) return;

    const summaryPrice = parseFloat(
      (price + userState?.user_info?.payment_total).toFixed(2)
    );
    //GT-14192. In this case, do not check for isFBConversion
    if (userState?.info?.age >= 35) {
      postRequest("/api/marketing/every_one_payment_conversion", {
        email: userState?.user_info?.email,
        external_id: userState?.user_info?.external_id,
        name: userState?.user_info?.name,
        b_year: userState?.user_info?.b_year,
        b_month: userState?.user_info?.b_month,
        b_day: userState?.user_info?.b_day,
        price: summaryPrice,
      });
    }
  };

  return {
    sentFBAuth,
    sentFBAuth55,
    sentFBDelfinConversion,
    sentFBConfirmEmailConversion,
    sentFBFirstPurchase,
    sentFBTwoMorePurchase,
    sentFBSpendAllCredits,
    sentFBEachPurchase,
  };
};

import cn from "classnames";

// @ts-ignore
import classes from "./Textonchat.module.scss";

interface IProps {
  className?: string;
  isSpan?: boolean;
  isHeader?: boolean;
  text?: any;
  size?: number;
  margin?: string;
  width?: string;
  textAlign?: any;
}

const Textonchat = ({
  isSpan,
  isHeader,
  text,
  size,
  margin,
  className,
  width,
  textAlign,
}: IProps) => {
  return (
    <>
      {isSpan ? (
        <span
          className={cn(classes.text, className && className)}
          style={{ fontSize: `${size}px`, margin: margin }}
        >
          {text}
        </span>
      ) : isHeader ? (
        <h2
          className={cn(classes.text, className && className)}
          style={{
            fontSize: `${size}px`,
            margin: margin,
            width: width,
            textAlign: textAlign,
          }}
        >
          {text}
        </h2>
      ) : (
        <p
          className={cn(classes.text, className && className)}
          style={{
            fontSize: `${size}px`,
            margin: margin,
            width: width,
            textAlign: textAlign,
          }}
        >
          {text}
        </p>
      )}
    </>
  );
};

export default Textonchat;

import { MetricsProvider } from "@cabify/prom-react";
import { MetricsInnerWrap } from "components/atomsKit/Elements";
import { MIRROR } from "data/mirrorConfig";

import { PAGE_PERFORMANCE_METRICS } from "data/config";
import * as PATH from "data/path";

interface IProps {
  children?: any;
}

const MetricsLayout = ({ children }: IProps) => {
  const hostname = window?.location?.hostname?.includes("localhost")
    ? `mstage.${MIRROR[
        // @ts-ignore
        process.env.REACT_APP_MIRROR_NAME
      ].NAME.toLowerCase()}.com`
    : window?.location?.hostname;

  const normalizePath = (path: string) => {
    for (const key in PATH.PATHS) {
      // @ts-ignore
      const replacePath = PATH.PATHS[key]?.replacePath;
      if (replacePath) {
        const regex = new RegExp(`(${replacePath})([^/]+)`, "g");
        if (regex.test(path)) {
          return path.replace(regex, `${replacePath}id`);
        }
      }
    }
    return path;
  };

  return (
    <MetricsProvider
      appName={hostname}
      owner="mobile"
      getNormalizedPath={normalizePath}
      // @ts-ignore
      customMetrics={PAGE_PERFORMANCE_METRICS}
      metricsAggregatorUrl="https://gateway.cdndate.net/push"
    >
      <MetricsInnerWrap children={children} />
    </MetricsProvider>
  );
};

export default MetricsLayout;

import { Box, Avatar, NameAge, Icon } from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";

import cn from "classnames";

// @ts-ignore
import classes from "./TooltipTransaction.module.scss";

const TooltipTransaction = ({
  id,
  message,
  name,
  age,
  iconType,
  imgLink,
  imgLinkUser = "",
  handleDel,
  handleCard,
  bgColor = "",
}) => {
  return imgLinkUser ? (
    <Box position={"relative"}>
      <Box
        className={cn(
          classes.wrap,
          bgColor === 1 && classes.wrap_bg1,
          bgColor === 2 && classes.wrap_bg2
        )}
        flex
        align={"center"}
        justify={"center"}
        onClick={() => {
          handleCard(id);
        }}
      >
        <p
          className={cn(
            classes.message,
            classes.message_double,
            bgColor === 1 && classes.message_white
          )}
        >
          {message}
        </p>
        <Box flex align="center">
          <Avatar small imgLink={imgLinkUser} />
          <Image name={iconType} className={classes.imageTypeDouble} />
          <Avatar small imgLink={imgLink} />
        </Box>
      </Box>
      <button className={classes.btn} onClick={() => handleDel(id)}>
        <Icon icon={"ok"} className={classes.icon} />
      </button>
    </Box>
  ) : (
    <Box position={"relative"}>
      <Box
        className={cn(
          classes.wrap,
          bgColor === 1 && classes.wrap_bg1,
          bgColor === 2 && classes.wrap_bg2
        )}
        flex
        align="center"
        onClick={() => {
          handleCard(id);
        }}
      >
        <Avatar small imgLink={imgLink} />
        <Box marginLeft="10px">
          {name && <NameAge name={name} age={age} />}
          <Box flex align={"start"}>
            {iconType && (
              <Image name={iconType} className={classes.imageType} />
            )}
            <p className={cn(classes.message, bgColor && classes.white)}>
              {message}
            </p>
          </Box>
        </Box>
      </Box>
      <button className={classes.btn} onClick={() => handleDel(id)}>
        <Icon
          icon={"ok"}
          className={cn(classes.icon, bgColor && classes.white)}
        />
      </button>
    </Box>
  );
};

export default TooltipTransaction;

import { useState, useEffect } from "react";
import { Howl } from "howler";

import NOTIFICATION_IPHONE_SOUND from "assets/audio/notification/iphone-sound.mp3";

const useMakeNotificationSound = () => {
  const [trigerPlaySound, setTrigerPlaySound] = useState(false);

  useEffect(() => {
    const iphoneSound = new Howl({ src: [NOTIFICATION_IPHONE_SOUND] });
    const isActiveSound = localStorage.getItem("sound");
    if (iphoneSound && isActiveSound === "1") {
      iphoneSound.play();
    }
    // eslint-disable-next-line
  }, [trigerPlaySound]);

  const playSound = () => {
    setTrigerPlaySound((prev) => !prev);
  };

  return { playSound };
};

export default useMakeNotificationSound;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { Howl } from "howler";
import { postRequest } from "utils/requests";
import { getDifferenceInSeconds } from "utils/common";
import NOTIFICATION_IPHONE_SOUND from "assets/audio/notification/iphone-sound.mp3";

import { addTransaction, delTransaction } from "../interface/slice";

export const searchList = createAsyncThunk(
  "girlsList/searchlist",
  async (params, thunkAPI) => {
    try {
      const response = await postRequest(
        `/api/v3/search/list/${params.pagination}`,
        params.filters
      );

      return { res: response.response, params: params };
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const setLike = createAsyncThunk(
  "girlsList/setlike",
  async (external_id, thunkAPI) => {
    try {
      const response = await postRequest("/api/like/setLike", {
        recipient_id: external_id,
      });

      if (response?.response?.match_object?.message_content === "MATCH") {
        const iphoneSound = new Howl({ src: [NOTIFICATION_IPHONE_SOUND] });
        const isActiveSound = localStorage.getItem("sound");
        if (iphoneSound && isActiveSound === "1") {
          iphoneSound.play();
        }

        const date_created = new Date().toISOString();
        const expired_at = new Date(Date.now() + 60000).toISOString();
        const item = {
          message: {
            id: response.response.notification_id,
            chatUID: response?.response?.match_object?.chat_uid,
            senderExternalID:
              response?.response?.match_object?.sender_external_id,
          },
          action: "MATCH",
          notification_object: {
            name: response?.response?.match_object?.name,
            age: response?.response?.match_object?.age,
            photo_link: response?.response?.match_object?.photo_link,
            date_created: date_created,
            expired_at: expired_at,
          },
        };
        thunkAPI.dispatch(addTransaction(item));
        setTimeout(() => {
          thunkAPI.dispatch(delTransaction(response.response.notification_id));
        }, getDifferenceInSeconds(date_created, expired_at));
      }

      return external_id;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const setFavorite = createAsyncThunk(
  "girlsList/setfavorite",
  async (req_body, thunkAPI) => {
    try {
      await postRequest("/api/chat/setFavorite", {
        chat_uid: req_body.chat_uid,
        favorite: req_body.favorite,
        user_id: req_body.user_id,
      });
      return req_body.favorite;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const userProfile = createAsyncThunk(
  "girlsList/userprofile",
  async (external_id, thunkAPI) => {
    try {
      const response = await postRequest("/api/v3/search/mobile-item", {
        girl_id: external_id,
      });
      if (response?.response?.content?.status === false) {
        return thunkAPI.rejectWithValue("not found");
      }
      return response.response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

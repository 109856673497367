const prefix =
  process.env.REACT_APP_API_MIRROR !== "Production" ? "mstage." : "m.";
const payPrefix =
  process.env.REACT_APP_API_MIRROR !== "Production" ? "stage." : "";

const MIRROR = {
  sofiadate: {
    MIRROR: process.env.REACT_APP_API_MIRROR,
    URL: `${prefix}sofiadate.com`,
    PAY_URL: `${payPrefix}sofiadate.com`,
    NAME: "SofiaDate",
    SUPPORT_MAIL: "support@sofiadate.com",
    GOOGLE_ID:
      "539249899214-o2vvebvr6d29as2nk6ap087ln2698704.apps.googleusercontent.com",
    SITE_ID: 1,
    BRAND_URL: "sofia20",
    COLORS: "colors_sofiadate.scss",
    LOGO: "logo_light_sofiadate",
    LOGO_LOGIN: "logo_sofia",
    LOGO_VIP: "logo_vip_light_sofiadate",
    COLOR_LOADER: "#00317B",
    LIGAL_TEXT: [
      "ASTRASOFT PROJECTS LTD",
      "Ifigeneias 14, 3036, Limassol, Cyprus",
      "Registration number HE 409810",
      "",
    ],
    SOCIAL_LIST: [
      { icon: "fs-3", link: "https://www.facebook.com/sofiadateblog/" },
      { icon: "fs-5", link: "https://www.instagram.com/sofia_date/" },
      { icon: "fs-6", link: "https://twitter.com/date_sofia" },
    ],
    LOGIN_BG_IMG: "main-bg-mob.jpg",
  },
  avodate: {
    MIRROR: process.env.REACT_APP_API_MIRROR,
    URL: `${prefix}avodate.com`,
    PAY_URL: `${payPrefix}avodate.com`,
    NAME: "AvoDate",
    SUPPORT_MAIL: "support@avodate.com",
    GOOGLE_ID:
      "539249899214-cnt6a46a7mmj5h60c7cv78ruv2m8rvu6.apps.googleusercontent.com",
    SITE_ID: 8,
    BRAND_URL: "avodate",
    COLORS: "colors_avo.scss",
    LOGO: "logo-light-avodate",
    LOGO_LOGIN: "logoAVO",
    LOGO_VIP: "site_logo_vip_mob_avo",
    COLOR_LOADER: "#289292",
    LIGAL_TEXT: [
      "SEMPITERNA DIGITAL LIMITED",
      "ELIAS VENEZI, 2A, ATHIENITIS STROVOLOS PARK, 4th Floor, Office 402, Strovolos, CY-01, 2042, CY",
      "Registration number HE 440404",
    ],
    SOCIAL_LIST: [
      { icon: "fs-3", link: "https://www.facebook.com/avodateblog/" },
      { icon: "fs-5", link: "https://instagram.com/avodate_blog" },
      { icon: "fs-6", link: "https://twitter.com/avo_date" },
    ],
    LOGIN_BG_IMG: "main-bg-avodate.png",
  },

  okamour: {
    MIRROR: process.env.REACT_APP_API_MIRROR,
    URL: `${prefix}okamour.com`,
    PAY_URL: `${payPrefix}okamour.com`,
    NAME: "Okamour",
    SUPPORT_MAIL: "support@okamour.com",
    GOOGLE_ID:
      "539249899214-ievhgh2eeqf2uuhh801m2jml1bv2dme8.apps.googleusercontent.com",
    SITE_ID: 7,
    BRAND_URL: "okamour",
    COLORS: "colors_okamour.scss",
    LOGO: "logo_light_okamour",
    LOGO_LOGIN: "logoOKAMOUR",
    LOGO_VIP: "site_logo_vip_mob_okamour",
    COLOR_LOADER: "#474f5c",
    LIGAL_TEXT: [
      "SEMPITERNA DIGITAL LIMITED",
      "ELIAS VENEZI, 2A, ATHIENITIS STROVOLOS PARK, 4th Floor, Office 402, Strovolos, CY-01, 2042, CY",
      "Registration number HE 440404",
    ],
    SOCIAL_LIST: [
      { icon: "fs-3", link: "https://www.facebook.com/okamour/" },
      { icon: "fs-5", link: "https://instagram.com/okamour_" },
      { icon: "fs-6", link: "https://x.com/okamour_" },
    ],
    LOGIN_BG_IMG: "main_bg_new_okamour.jpg",
  },
};

module.exports = { MIRROR };

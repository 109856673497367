import { Box, InfoGirl } from "components/atomsKit/Elements";

// import cn from "classnames";

// @ts-ignore
import classes from "./SendGiftCard.module.scss";

interface IProps {
  id: number;
  image: string;
  name: string;
  price: number;
  public_external_id: number;
  nameGirl: string;
  ageGirl: number;
  avatarGirl: string;
  date_created: string;
  status: number;
}

const SendGiftCard = ({
  id,
  image,
  name,
  price,
  public_external_id,
  nameGirl,
  ageGirl,
  avatarGirl,
  date_created,
  status,
}: IProps) => {
  return (
    <Box className={classes.wrap}>
      <Box flex marginBottom="24px">
        <img src={image} alt="" className={classes.photo} loading="lazy" />
        <Box>
          <p className={classes.id}>{`Gift ID: ${id}`}</p>
          <h2 className={classes.name}>{name}</h2>
          <p className={classes.price}>
            Price <span className={classes.cost}>{+price} credits</span>
          </p>
        </Box>
      </Box>
      <Box flex align="center">
        <p style={{ marginRight: "16px" }}>To:</p>
        <InfoGirl
          imgLink={avatarGirl}
          name={nameGirl}
          public_external_id={public_external_id}
          age={ageGirl}
        />
      </Box>
      <Box
        className={classes.info}
        marginTop="10px"
        padding="10px 0 0 0"
        flex
        directionColumn
        align="center"
        justify="center"
      >
        <p className={classes.textInfo}>
          Order make <span style={{ marginLeft: "16px" }}>{date_created}</span>
        </p>
        <p className={classes.textStatus}>
          STATUS:
          <span style={{ marginLeft: "16px" }}>
            {status === 0 ? "In progress" : "Approved"}
          </span>
        </p>
        <p className={classes.textInfo}>Time of delivery 10 days</p>
      </Box>
    </Box>
  );
};

export default SendGiftCard;

import { useSelector } from "react-redux";
import { postRequest } from "utils/requests";

export const useHandleRedUser = () => {
  const userState = useSelector((state) => state?.auth?.user?.user_info);

  const submitLog = (type, extra = "") => {
    try {
      if (!type) return null;
      if (userState?.configType !== 1) return null;

      postRequest(`/api/v3/config/type/log/create`, { type, extra });
    } catch (err) {
      return null;
    }
  };

  return {
    submitLog,
  };
};

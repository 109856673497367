import { useSelector } from "react-redux";

import { selectMyProfile } from "redux/auth/selectors";

const usePaymentPage = () => {
  const user_detail = useSelector(selectMyProfile)?.user_detail;

  return {
    user_detail,
  };
};

export default usePaymentPage;

import { useState } from "react";
import { ModalWindow } from "components/atomsKit/Elements";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/scss/image-gallery.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import { images } from "utils/images";

// @ts-ignore
import classes from "./PhotoSlider.module.scss";

const PhotoSlider = (props) => {
  const { photos } = props;

  const counterPrivatPhoto = photos?.filter(
    (image) => +image?.free === 0 && !image?.payed
  )?.length;

  const [isOpenBigFoto, setIsOpenBigPhoto] = useState(false);
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(1);

  const modalClose = () => {
    setIsOpenBigPhoto(false);
  };

  const handlePhoto = (e) => {
    setIsOpenBigPhoto(true);
  };

  const handleSwipe = (index) => {
    setCurrentPhotoIndex(index);
  };

  const renderGalleryItem = (item) => {
    return (
      <div className={classes.image}>
        <img src={item.original} alt="" className={classes.img} />
        {!item.free && (
          <div className={classes.textBlurePhoto}>
            <p className={classes.counterText}>Private photo</p>
            <p className={classes.infoText}>
              To view member’s private photos, start chatting with her
            </p>
          </div>
        )}
      </div>
    );
  };

  const renderGalleryItemCounter = (item) => {
    return (
      <div className={classes.image}>
        <img src={item.original} alt="" className={classes.img} />
        {!item.free && (
          <div className={classes.textBlurePhoto}>
            <p className={classes.counterText}>
              This member has {counterPrivatPhoto} other private photos
            </p>
            <p className={classes.infoText}>
              To view member’s private photos, start chatting with her
            </p>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <ModalWindow modalIsOpen={isOpenBigFoto} closeModal={modalClose}>
        <div className={classes.bigPhotoSliderWrap}>
          <ImageGallery
            items={images(photos)}
            showPlayButton={false}
            showThumbnails={false}
            showFullscreenButton={false}
            startIndex={currentPhotoIndex}
            onSlide={handleSwipe}
            renderItem={renderGalleryItemCounter}
          />
        </div>
      </ModalWindow>
      <ImageGallery
        thumbnailPosition={"right"}
        items={images(photos)}
        onClick={handlePhoto}
        showPlayButton={false}
        showFullscreenButton={false}
        onSlide={handleSwipe}
        loading
        renderItem={renderGalleryItem}
      />
    </>
  );
};

export default PhotoSlider;

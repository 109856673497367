import { Icon } from "components/atomsKit/Elements";
import cn from "classnames";

// @ts-ignore
import classes from "./CloseButton.module.scss";

interface IProps {
  onClick?: any;
  color?: string;
  iconClasses?: any;
  btnClasses?: any;
}

const CloseButton = ({ onClick, btnClasses }: IProps) => {
  return (
    <button className={cn(classes.btnClose, btnClasses)} onClick={onClick}>
      <Icon icon={"ok"} className={classes.iconClasses} color={"#CDD3DA"} />
    </button>
  );
};

export default CloseButton;

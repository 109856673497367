import { BrowserRouter } from "react-router-dom";

import { apiInit } from "utils/apiClient";

import Layout from "./Layout/Layout";
import { RotateMessage, MetricsLayout } from "components/atomsKit/Elements";

import { useDetectedDevice } from "hooks";

import "./App.scss";
import "./assets/font/stylesheet.css";

function App() {
  apiInit();
  setTimeout(() => {
    document.documentElement.setAttribute("data-theme", "light");
  }, 1000);

  const { isMobile, isTablet, isHorizontal } = useDetectedDevice();

  if (isMobile && !isTablet && isHorizontal) {
    return <RotateMessage />;
  }

  return (
    <MetricsLayout>
      <BrowserRouter>
        <div className="App">
          <Layout />
        </div>
      </BrowserRouter>
    </MetricsLayout>
  );
}

export default App;

import { useSelector } from "react-redux";
import { postRequest } from "utils/requests";

export const useAwinConversion = () => {
  const userState = useSelector((state) => state?.auth?.user?.user_info);
  const tail = useSelector((state) => state?.auth?.tail?.response?.tail);

  const checkAwinTag = tail?.includes("utm_source=awin");
  const checkCountry = userState?.country_code?.toUpperCase() === "US";
  const age35 = (userState?.age || 0) >= 35;
  const age45 = (userState?.age || 0) >= 45;

  const sendAwinReg = () => {
    if (checkAwinTag && checkCountry) {
      if (age45) {
        postRequest("/api/marketing/awinConversion", { type: "REG45" });
      } else if (age35) {
        postRequest("/api/marketing/awinConversion", { type: "REG35" });
      }
    }
  };

  const sendAwinFirstSale = () => {
    if (checkAwinTag && checkCountry && age35) {
      postRequest("marketing/awinConversion", { type: "SALE" });
    }
  };

  return {
    sendAwinReg,
    sendAwinFirstSale,
  };
};

import { useState } from "react";
import { useSelector } from "react-redux";

import { selectInfo } from "redux/auth/selectors";

const useTemplate = () => {
  const [choseFaq, setChoseFaq] = useState();
  const [idSelectTopic, setIdSelectTopic] = useState();

  const faqList = useSelector(selectInfo).faq;

  return {
    faqList,
    setChoseFaq,
    choseFaq,
    idSelectTopic,
    setIdSelectTopic,
  };
};

export default useTemplate;

// import { Icon } from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";
// import cn from "classnames";
// @ts-ignore
import classes from "./Logo.module.scss";

interface IProps {
  onClick?: () => void;
  logo?: string;
}
const Logo = ({ onClick, logo }: IProps) => {
  return (
    <h1 className={classes.wrap}>
      <button onClick={onClick} className={classes.button}>
        <Image name={logo as string} className={classes.logo} />
      </button>
    </h1>
  );
};

export default Logo;

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";

import { selectUser } from "redux/auth/selectors";

import {
  updateStatusOnlineGirlList,
  updateStatusOnlineProfileCard,
} from "redux/girlsList/slice";
import {
  togleReFetch,
  addTransaction,
  delTransaction,
  addNotification,
  delNotification,
} from "redux/interface/slice";
import {
  readStatus,
  updateStatusOnlineChatList,
  updateStatusOnlineMessager,
} from "redux/chat/slice";
import {
  updateStatusOnlineLetterList,
  updateStatusOnlineLetterProfile,
} from "redux/letters/slice";

import { chatCounter } from "redux/interface/operations";

import useMakeNotificationSound from "hooks/useMakeNotificationSound";

import {
  useSuccessPaymentCallback,
  useAuth,
  useGoogleDataLayer,
  useTransactionConfig,
  useHandleRedUser,
} from "hooks";

import { initSocket, getSocket } from "utils/socket";

import { postActionTransaction } from "redux/interface/operations";
import { logOut } from "redux/auth/operations";
import { setUserSpendFirstCredit } from "redux/auth/slice";

import { getDifferenceInSeconds } from "utils/common";
import { sendAfficeFromStore } from "utils/affise";
import { sendBinomoFromStore } from "utils/binomo";

import * as USER from "data/user";

// import * as SYNC from "data/sync";

const SocketProvider = () => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const transactionConfig = useTransactionConfig();
  const successPaymentCallback = useSuccessPaymentCallback();
  const handleRedUser = useHandleRedUser();
  const { pushEventTryPay, pushEventFirstCreditSpent } = useGoogleDataLayer();

  const userExternalId = useSelector(selectUser)?.user_info?.external_id;

  const userState = useSelector((state) => state?.auth?.user?.user_info);
  const abTest = useSelector((state) => state?.auth?.user?.abTest);

  const tail = useSelector((state) => state?.auth?.tail?.response?.tail);

  const dispatch = useDispatch();

  const { playSound } = useMakeNotificationSound();

  useEffect(() => {
    if ((isLoggedIn, userState?.external_id)) {
      initSocket();
      // @ts-ignore
      getSocket().on(`counters_profile_${userState?.external_id}`, (event) => {
        actionSocket(event);
      });
      getSocket().on(`woman_info_channel_0`, (event) => {
        actionSocket(event);
      });
    }
    // eslint-disable-next-line
  }, [isLoggedIn, userState?.external_id]);

  const actionSocket = (event) => {
    const { action } = event;
    dispatch(togleReFetch(event));

    switch (action) {
      case "FRONT_SYNC_TRANSACTION":
        postActionTransaction(event?.message);
        break;
      case "NEW_GIRL":
      case "VIEW_PROFILE":
      case "MAYBE_APPEAL":
      case "POSSIBLE_MATCH":
      case "NO_CHAT":
      case "MAYBE_CHAT":
      case "STRANGER":
      case "SEARCH_PARTNER":
      case "PROFILE_UPDATE":
      case "ONLINE_NOW":
      case "MATCH":
      case "LIKE":
        if (event?.notification_object) {
          playSound();
          const idTransaction = nanoid(5);
          dispatch(addTransaction({ ...event, idTransaction: idTransaction }));
          setTimeout(() => {
            dispatch(delTransaction(idTransaction));
          }, getDifferenceInSeconds(event?.notification_object?.date_created, event?.notification_object?.expired_at));
        }
        break;
      case "open_chat":
        dispatch(readStatus(event));
        break;

      case "user_logout":
        dispatch(logOut());
        break;

      case "message":
      case "liked":
      case "mail":
        playSound();
        dispatch(chatCounter(userExternalId));
        const idTransaction = nanoid(5);
        dispatch(addNotification({ ...event, idTransaction: idTransaction }));
        setTimeout(() => {
          dispatch(delNotification(idTransaction));
        }, getDifferenceInSeconds(event?.notification_object?.date_created, event?.notification_object?.date_expired || event?.notification_object?.expired_at));
        break;
      case "online_status":
        dispatch(updateStatusOnlineGirlList(event));
        dispatch(updateStatusOnlineProfileCard(event));
        dispatch(updateStatusOnlineChatList(event));
        dispatch(updateStatusOnlineMessager(event));
        dispatch(updateStatusOnlineLetterList(event));
        dispatch(updateStatusOnlineLetterProfile(event));
        break;

      // Analitics
      case "ga_event":
        // !!! DEL BEFOR PROD
        console.log("ga_event");
        sendBinomoFromStore({
          externalID: userState?.external_id,
          siteID: userState?.site_id,
          status: USER.BINOMO_SPEND_FIRST_CREDITS,
          clickid: tail?.response?.clickid,
          utm_aff_hash: tail?.utm_aff_hash,
          tail: tail?.response?.tail,
          info: userState,
        });
        sendAfficeFromStore({
          status: USER.AFFISE_SPEND_FIRST_CREDITS,
          info: userState?.info,
        });

        if (event?.message === "spend-first-credit") {
          dispatch(setUserSpendFirstCredit());

          // This applies only to bonuses - free credits
          if (!document.hidden && !+userState.payment_total) {
            pushEventFirstCreditSpent();
          }
        }

        break;

      case "credits_issue":
        // !!! DEL BEFOR PROD
        console.log("credits_issue");
        handleRedUser.submitLog(
          USER.RED_USER_LOG_TYPES.PAYMENT_DECLINE,
          JSON.stringify(event?.message)
        );

        if (!document.hidden && event?.message?.count === 0) {
          pushEventTryPay();
        }

        break;

      case "credits":
        if (event?.message?.status) {
          if (+abTest && !+userState?.ghost) {
            transactionConfig.fetchTransactionConfig();
          }

          handleRedUser.submitLog(
            USER.RED_USER_LOG_TYPES.PAYMENT_SUCCESS,
            JSON.stringify(event?.message)
          );
          successPaymentCallback({
            count: +event?.message?.total_packages,
            credits: event?.message?.credits,
            price: +event?.message?.price,
          });
        }

        navigate("/chat");
        break;

      default:
        break;
    }
  };

  return <div />;
};

export default SocketProvider;

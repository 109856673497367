import { Box } from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";

import { useNavigate } from "react-router-dom";

// import cn from "classnames";

// @ts-ignore
import classes from "./BanerHeaderVip.module.scss";

interface IProps {
  className?: string;
  handleCloseBaner?: any;
}

const BanerHeaderVip = ({ handleCloseBaner, className }: IProps) => {
  const navigate = useNavigate();
  return (
    <Box className={classes.wrap}>
      <Box flex align="center">
        <Image name="vip_banner" />
        <Box marginLeft="8px">
          <p className={classes.textTop}>
            <span className={classes.textVip}>Get VIP</span> and
          </p>
          <p className={classes.textTop}>bonus 100 credits</p>
        </Box>
      </Box>
      <Box flex align="center" justify="center">
        <button
          className={classes.btnConfirm}
          onClick={() => navigate("/profile")}
        >
          <span className={classes.textBtn}>Get VIP</span>
        </button>
        <button onClick={handleCloseBaner}>
          <Image name="cros" className={classes.btn} />
        </button>
      </Box>
    </Box>
  );
};

export default BanerHeaderVip;

import { Icon } from "components/atomsKit/Elements";
import { NameonChat } from "components/designAtom/Elements";

import cn from "classnames";

// @ts-ignore
import classes from "./Avatar.module.scss";

interface IProps {
  imgLink?: string;
  isOnline?: boolean;
  small?: boolean;
  big?: boolean;
  isFavorite?: boolean;
  user_name?: string;
  className?: any;
}

const Avatar = ({
  imgLink,
  isOnline,
  small,
  big,
  isFavorite,
  user_name,
  className,
}: IProps) => {
  return (
    <div className={cn(classes.wrapAvatar, className)}>
      {imgLink ? (
        <img
          loading="lazy"
          src={imgLink}
          alt=""
          className={cn(
            classes.avatar,
            small && classes.avatar_small,
            big && classes.avatar_big
          )}
        />
      ) : (
        <NameonChat
          textAlign={"center"}
          text={user_name ? user_name.slice(0, 2) : ""}
          className={cn(classes.avatar, classes.avatar_small, classes.nameUser)}
          size={14}
        />
      )}
      {isOnline && (
        <div
          className={cn(
            classes.isOnline,
            small && classes.isOnline_small,
            big && classes.isOnline_big
          )}
        />
      )}
      {isFavorite && <Icon icon="star" className={classes.favorite} />}
    </div>
  );
};

export default Avatar;

import { postRequest } from "utils/requests";
import { browserName, engineName } from "react-device-detect";

export const useHandleBilling = () => {
  const createBillingLog = (action, extra = null) => {
    const clientData = {
      getEngine: engineName, //
      getAvailableResolution: `${window?.screen?.width}x${window?.screen?.height}`, //
      getBrowser: browserName,
      getLanguage: window?.navigator?.language,
    };

    const logData = {
      deviceID: localStorage.getItem("fingerprinthash"),
      device: JSON.stringify(clientData),
      link: window.location.href,
      action,
      ...(extra ? { extra: JSON.stringify(extra) } : {}),
    };

    postRequest(`/api/v3/billing/log`, logData);
  };

  return { createBillingLog };
};

import cn from "classnames";

import {
  ButtonIcon,
  Logo,
  ButtonAlert,
  Icon,
} from "components/atomsKit/Elements";
// @ts-ignore
import classes from "./Header.module.scss";

interface IProps {
  onClickService?: any;
  textService: string;
  colorService: string;
  iconService: string;
  onClickLogo: () => void;
  onCLickAllert: () => void;
  iconAlert?: string;
  alertCounter?: number;
  isHorizontalVersion?: boolean;
  isOpenFilter?: boolean;
  pressColorService?: string;
  isOpenAlert?: boolean;
  logo?: string;
}

const Header = ({
  onClickService,
  textService,
  colorService,
  pressColorService,
  iconService,
  onClickLogo,
  onCLickAllert,
  alertCounter,
  isHorizontalVersion,
  isOpenFilter,
  isOpenAlert,
  logo,
}: IProps) => {
  return (
    <section
      className={cn(classes.wrap, isHorizontalVersion && classes.wrap_horizont)}
    >
      {onClickService ? (
        <>
          <ButtonIcon
            onClick={onClickService}
            icon={iconService}
            color={isOpenFilter ? pressColorService : colorService}
            text={textService}
            className={isOpenFilter ? classes.openFilter : classes.closeFilter}
          />
          {isOpenFilter && (
            <Icon
              icon={"filterOpen"}
              className={cn(classes.icon, classes.icon_left)}
            />
          )}
        </>
      ) : (
        <ButtonIcon
          disabled
          icon={""}
          color={isOpenFilter ? pressColorService : colorService}
          text={""}
          className={isOpenFilter ? classes.openFilter : classes.closeFilter}
        />
      )}
      <Logo onClick={onClickLogo} logo={logo} />
      <ButtonAlert
        onClick={onCLickAllert}
        isHorizontalVersion={isHorizontalVersion}
        counter={alertCounter}
        className={cn(isOpenAlert && classes.openAlert)}
      />
      {isOpenAlert && (
        <Icon
          icon={"filterOpen"}
          className={cn(classes.icon, classes.icon_right)}
        />
      )}
    </section>
  );
};

export default Header;

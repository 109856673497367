import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { postRequest } from "utils/requests";

import { addGift } from "redux/interface/slice";
import { selectedGifts } from "redux/interface/selectors";

const useLettersPage = () => {
  const [showAllCategory, setShowAllCategory] = useState(false);
  const [configGifts, setConfigGifts] = useState([]);
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector(selectedGifts);

  const [selectedGroup, setSelectedGroup] = useState({ name: "All" });

  useEffect(() => {
    postRequest("/api/shop/categories").then((res) => {
      setConfigGifts(res?.result);
    });

    postRequest("/api/shop/products").then((res) => {
      setProducts(res?.result);
    });
  }, []);

  const handleAddCart = (item) => {
    dispatch(addGift(item));
  };

  const handleCart = () => {
    navigate("/gifts/cart");
  };

  return {
    showAllCategory,
    setShowAllCategory,
    configGifts,
    setSelectedGroup,
    selectedGroup,
    products,
    handleAddCart,
    cart,
    handleCart,
  };
};

export default useLettersPage;

import io from "socket.io-client";
import { SOCKET_PATH } from "data/sync";

import { MIRROR } from "data/mirrorConfig";

let socket;

export const initSocket = () => {
  if (socket) {
    socket.removeAllListeners();
    socket.close();
  }

  socket = io(
    `https://${MIRROR[process.env.REACT_APP_MIRROR_NAME].URL}`,
    {
      autoConnect: true,
      path: SOCKET_PATH,
      forceNew: true,
      withCredentials: false,
      transportOptions: {
        polling: {
          extraHeaders: {
            // @ts-ignore
            "X-USER-TOKEN": localStorage.getItem("token"),
          },
        },
      },
    }
  );
};

export const getSocket = () => {
  if (!socket) {
    return false;
  }
  return socket;
};

import { useNavigate, useLocation } from "react-router-dom";
import { getUrlParameter } from "utils/common";

export function useAddTail() {
  const currentLocation = useLocation();
  const navigate = useNavigate();

  return (key, value) => {
    try {
      const param = key + "=" + value;
      let searchStr = window?.location?.search;

      if (searchStr?.includes(key)) {
        searchStr = searchStr.replace(
          `${key}=${getUrlParameter(key)}`,
          `${key}=${value}`
        );
      } else {
        searchStr = searchStr?.length ? `${searchStr}&${param}` : `?${param}`;
      }

      navigate(`${currentLocation?.pathname}${searchStr ?? ""}`, {
        replace: true,
      });
    } catch (e) {
      console.error(e);
    }
  };
}

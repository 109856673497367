import cn from "classnames";

import {
  Button,
  PageWrap,
  Box,
  ModalFilter,
  FilterBody,
  SliderSimilarGigls,
} from "components/atomsKit/Elements";
import { NameonChat, Image, Textonchat } from "components/designAtom/Elements";

import { parserFilterOptions } from "utils/common";

// import cn from "classnames";

import useLikeHerPage from "./useLikeHerPage";

// @ts-ignore
import classes from "./LikeHerPage.module.scss";

const LikeHerPage = () => {
  const {
    girlsList,

    handleTouchStart,
    handleTouchMove,
    handleTouchEnd,
    swipeLeft,
    swipeRight,
    swipeBack,
    handleChat,
    isOpenFirst,
    currentCard,
    handleSimilar,

    isOpenFilter,
    modalFilterClose,
    filtersOptions,
    onChangeOption,
    valuesFilter,
    handleCard,
  } = useLikeHerPage();

  return (
    <>
      <ModalFilter modalIsOpen={isOpenFilter} closeModal={modalFilterClose}>
        <FilterBody
          filtersOptions={parserFilterOptions(filtersOptions)}
          onChangeOption={onChangeOption}
          valuesFilter={valuesFilter}
        />
      </ModalFilter>
      <PageWrap height100 startPositionY={60} mutationHeight={10}>
        {girlsList.length > 0 && (
          <>
            <Box
              padding="0 4px 0 4px"
              flex
              justify={currentCard > 0 ? "between" : "end"}
            >
              {currentCard > 0 && (
                <button className={classes.btnPrev} onClick={() => swipeBack()}>
                  <Image
                    name={"left-arrow"}
                    className={classes.arrow}
                    bgImg
                    classNameIcon="btnIco"
                  />
                  Previous one
                </button>
              )}

              <p className={classes.btnPrev}>Up next</p>
            </Box>
            <SliderSimilarGigls
              initialSlide={currentCard}
              similarList={girlsList}
              handleSimilar={handleSimilar}
              className={classes.similar}
              navigation
            />
          </>
        )}
        {girlsList.length > 0 && currentCard <= girlsList.length - 1 ? (
          <>
            {isOpenFirst && (
              <Box className={classes.tutorialWrap}>
                <Box
                  width100
                  flex
                  directionColumn
                  align="center"
                  className={classes.dotted}
                >
                  <Image name={"touch"} className={classes.icoTouchRight} />
                  <NameonChat
                    textAlign="center"
                    margin="20px 0 0 0"
                    text={"Swipe left to next"}
                    size={15}
                    isSpan
                    className={classes.infoText}
                  />
                </Box>
                <Box flex directionColumn align="center" width100>
                  <Image name={"touch"} className={classes.icoTouch} />
                  <NameonChat
                    textAlign="center"
                    margin="20px 0 0 0"
                    text={"Swipe right to like"}
                    size={15}
                    isSpan
                    className={classes.infoText}
                  />
                </Box>
              </Box>
            )}

            <>
              {/* Вторая карточка */}
              {girlsList[currentCard + 1]?.id && (
                <div
                  key={girlsList[currentCard + 1]?.id}
                  id={girlsList[currentCard + 1]?.id}
                  className={classes.card2}
                  style={{
                    backgroundImage: `url(${
                      girlsList[currentCard + 1]?.search_img
                    })`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <Box>
                    <NameonChat
                      text={girlsList[currentCard + 1]?.name}
                      size={30}
                      isSpan
                      className={classes.infoText}
                    />
                    <NameonChat
                      text=", "
                      size={30}
                      isSpan
                      className={classes.infoText}
                    />
                    <NameonChat
                      text={girlsList[currentCard + 1]?.age}
                      size={30}
                      isSpan
                      className={classes.infoText}
                    />
                  </Box>
                </div>
              )}

              {/* Первоя карточка */}
              {girlsList[currentCard]?.id && (
                <div
                  key={girlsList[currentCard]?.id}
                  id={girlsList[currentCard]?.id}
                  className={cn(
                    classes.card,
                    girlsList.length > 0 && classes.card_similar
                  )}
                  style={{
                    backgroundImage: `url(${girlsList[currentCard]?.search_img})`,
                    backgroundSize: "cover",
                    backgroundPosition: "top",
                    backgroundRepeat: "no-repeat",
                  }}
                  onTouchStart={handleTouchStart}
                  onTouchMove={(e) =>
                    handleTouchMove(
                      e,
                      girlsList[currentCard]?.id,
                      girlsList[currentCard + 1]?.id
                    )
                  }
                  onTouchEnd={() =>
                    handleTouchEnd(
                      girlsList[currentCard]?.id,
                      girlsList[currentCard + 1]?.id
                    )
                  }
                  onClick={() => {
                    handleCard(girlsList[currentCard]?.external_id);
                  }}
                >
                  <Box>
                    <NameonChat
                      text={girlsList[currentCard]?.name}
                      size={30}
                      isSpan
                      className={classes.infoText}
                    />
                    <NameonChat
                      text=", "
                      size={30}
                      isSpan
                      className={classes.infoText}
                    />
                    <NameonChat
                      text={girlsList[currentCard]?.age}
                      size={30}
                      isSpan
                      className={classes.infoText}
                    />
                  </Box>
                </div>
              )}
            </>

            <Box flex padding="10px" className={classes.wrapBtn}>
              <Button
                disabled={currentCard >= girlsList?.length}
                className={classes.btn}
                isWhite
                iconSvg="arrow"
                onClick={() => swipeLeft(girlsList[currentCard]?.id)}
                text={"Next Member"}
                bgImg
                classNameIcon="btnIco"
              />
              <Button
                disabled={currentCard >= girlsList?.length}
                className={classes.btn}
                isWhite
                iconSvg="chat"
                onClick={() => {
                  handleChat("null", girlsList[currentCard]?.external_id);
                }}
                text={"Chat Now"}
                bgImg
                classNameIcon="btnIco"
              />
              <Button
                disabled={currentCard >= girlsList?.length}
                className={classes.btn}
                isWhite
                iconSvg="heart"
                onClick={() => swipeRight(girlsList[currentCard]?.id)}
                text={"Like"}
                bgImg
                classNameIcon="btnIco"
              />
            </Box>
          </>
        ) : (
          <PageWrap height100>
            <Box
              flex
              align="center"
              justify="center"
              directionColumn
              height100
              padding={"0 16px"}
            >
              <Image name="sweetheart_image" className={classes.emptyImg} />
              <NameonChat
                margin="20px 0 0 0"
                size={18}
                text={"No results found based on filters you applied"}
                textAlign={"center"}
              />
              <Textonchat
                textAlign={"center"}
                margin="20px 0 20px 0"
                text={
                  "Please clear or change filter parameters to see relevant results"
                }
              />
              <Button
                fitContent
                padding="0 16px 0 16px"
                onClick={() => {}}
                isWhite
                text="Clear all filters"
              />
            </Box>
          </PageWrap>
        )}
      </PageWrap>
    </>
  );
};

export default LikeHerPage;

import { Box, Button } from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";

import styles from "./styles.module.scss";

export default function Step1() {
  return (
    <Box
      flex
      className={styles.profileWrap}
      align="center"
      justify="center"
      width100
      height100
    >
      <Box className={styles.avatarNone} flex align="center" justify="center">
        <Image name="profileIcon" className={styles.profile} />
      </Box>
      <Box width100 marginLeft="10px" flex directionColumn justify={"center"}>
        <Button text="My profile" isWhite icon={"user"} margin="0 0 10px 0" />
        <Button text={"Add credits"} iconSvg={"copy_white"} />
      </Box>
    </Box>
  );
}

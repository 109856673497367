export function initPosthog(externalID, name) {
  try {
    if (window?.posthog && !window.posthog?.__loaded) {
      window.posthog.init("phc_m5TIYPDyVFWQMkrOP527y2C8JjQw7gglkc5AGxS7FTZ", {
        api_host: "https://posthog.cdndate.net",
        session_recording: {
          recordCrossOriginIframes: true,
        },
      });
      window.posthog.identify(externalID, { name });
    }
  } catch (err) {}
}

import { useRef } from "react";
import cn from "classnames";

import {
  Stick,
  Button,
  PhotoSlider,
  SliderSimilarGigls,
  Box,
  ModalVideoPlayer,
} from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";
import InfoList from "./InfiList";

import {
  appearanceConfig,
  socialInfoConfig,
  summaryConfig,
  whoAmILookingConfig,
  hobbyAndHabitsConfig,
} from "data/config";
import { sortCardsToFreeFirst } from "utils/common";

// @ts-ignore
import classes from "./GirlProfile.module.scss";

const GirlProfile = (props) => {
  const {
    cardInfo,
    handleSimilar,
    videoLink,
    handleVideoPlay,
    closeModalVideo,
    isOpenModalVideo,
    handleFavorite,
    handleChat,
    handleLike,
    handleLetters,
    isLikeGirl,
    handleSayHello,
    setIsOpenModalContact,
    handleSendGift,
    similarList,
    isContactRequest,
    isHideGift,
  } = props;

  const contWrap = useRef(null);

  const profile = cardInfo?.profile?.result?.profile;
  const isLiked = cardInfo?.profile?.result?.chat?.female_like;
  const content = sortCardsToFreeFirst(cardInfo.content.result);
  const wink = !!cardInfo?.profile?.result?.chat?.wink;
  return (
    <div className={classes.wrapScroll} ref={contWrap}>
      <div className={classes.cardWrap}>
        <h2 className={classes.name}>
          {profile.name}, {profile.age}
        </h2>
        {!!profile.online && (
          <Stick text="I’m online now" className={classes.stik} />
        )}
        {!wink && (
          <Button
            onClick={() => {
              handleSayHello();
            }}
            fitContent
            text="Say Hello"
            iconSvg="say-hello-yellow"
            isWhite
            className={classes.btnSayHello}
          />
        )}

        <p className={classes.age}>{profile.date_birth}</p>
        <div className={classes.idWrap}>
          <p className={classes.id}>ID {profile.public_external_id}</p>

          <p className={classes.flag}>
            <img
              src={profile.country_icon}
              alt=""
              width="15px"
              loading="lazy"
            />
          </p>
          <p className={classes.flag}>
            {profile.city_name}, {profile.country_name}
          </p>
        </div>
        <div className={classes.sliderWrap}>
          <PhotoSlider photos={content} />
        </div>

        {videoLink && (
          <>
            <ModalVideoPlayer
              isLikeGirl={isLiked || isLikeGirl}
              handleLike={() => {
                handleLike(profile.external_id);
              }}
              handleChat={() =>
                handleChat(
                  cardInfo.profile.result.chat.chat_uid,
                  profile.external_id
                )
              }
              handleLetters={() => handleLetters(profile.public_external_id)}
              handleSendGift={() => handleSendGift(profile.external_id)}
              isOpen={isOpenModalVideo}
              data={{
                name: profile.name,
                city: profile.city_name,
                flag: profile.country_icon,
                age: profile.age,
              }}
              closeModalVideo={closeModalVideo}
            />
            <Box marginTop="20px" position={"relative"}>
              <h2 className={classes.title}>{"Video"}</h2>
              <button
                onClick={() => {
                  handleVideoPlay(videoLink.thumbnail_id);
                }}
              >
                <Image name="play-btn" className={classes.imgPlay} />
                <img
                  src={videoLink.base_link}
                  alt=""
                  className={classes.video}
                  loading="lazy"
                />
              </button>
            </Box>
          </>
        )}
        <div className={classes.wrapBtn}>
          <Button
            onClick={() => handleLetters(profile.public_external_id)}
            icon="mail"
            text="Write a letter"
            isWhite
            className={cn(
              classes.btn,
              profile.is_blocked && classes.btn_blocked
            )}
          />
          {!isHideGift && (
            <Button
              onClick={() => handleSendGift(profile.external_id)}
              iconSvg="profile-gift"
              text="Send a gift"
              isWhite
              className={classes.btn}
              bgImg
              classNameIcon="btnIco"
            />
          )}

          <Button
            className={classes.btn}
            onClick={() => {
              handleFavorite(cardInfo.profile.result.chat.chat_uid);
            }}
            icon={
              cardInfo.profile.result.chat.female_favorite === 0
                ? "start-border"
                : "star"
            }
            text={
              cardInfo.profile.result.chat.female_favorite === 0
                ? "Add to favorites"
                : "Added to favorites"
            }
            isWhite
          />
        </div>
      </div>
      {isContactRequest && (
        <div className={classes.cardWrap}>
          <Button
            text="Contact Request"
            onClick={() => {
              setIsOpenModalContact(true);
            }}
          />
        </div>
      )}

      <div className={classes.cardWrap}>
        <InfoList config={appearanceConfig} data={profile} />
        <InfoList config={socialInfoConfig} data={profile} />
        <InfoList config={hobbyAndHabitsConfig} data={profile} />
        <InfoList config={summaryConfig} text={profile.about} />
        <InfoList config={whoAmILookingConfig} text={profile.seeking} />
        {similarList.length > 0 && (
          <SliderSimilarGigls
            title={"Similar girls"}
            placeholder={"View those lovely members"}
            similarList={similarList}
            className={classes.similar}
            handleSimilar={handleSimilar}
          />
        )}
      </div>
      <div className={classes.fixBtnWrap}>
        <Button
          onClick={() =>
            handleChat(
              cardInfo.profile.result.chat.chat_uid,
              profile.external_id
            )
          }
          icon="chat"
          text="Start chatting"
          className={classes.btn}
        />
        <Button
          disabled={isLiked || isLikeGirl}
          onClick={() => {
            handleLike(profile.external_id);
          }}
          iconSvg={isLiked || isLikeGirl ? "profile-like" : "c3lh-item-like"}
          text={isLiked || isLikeGirl ? "Liked" : "Like"}
          className={classes.btn}
          isWhite
          c3lh-item-like
          bgImg={isLiked || isLikeGirl ? false : true}
          classNameIcon={"btnIco"}
        />
      </div>
    </div>
  );
};

export default GirlProfile;

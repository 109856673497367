// import { Button } from "components/atomsKit/Elements";

import cn from "classnames";

// @ts-ignore
import classes from "./NameAge.module.scss";

interface IProps {
  name: string;
  age: number;
  whiteColor?: boolean;
}

const NameAge = ({ name, age, whiteColor }: IProps) => {
  return (
    <p
      className={cn(classes.name, whiteColor && classes.whiteColor)}
    >{`${name}, ${age}`}</p>
  );
};

export default NameAge;

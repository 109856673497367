import sticker_heart_arrow from "./heart/sticker-heart-arrow.svg";
import sticker_heart_broken from "./heart/sticker-heart-broken.svg";
import sticker_heart_confused from "./heart/sticker-heart-confused.svg";
import sticker_heart_cry from "./heart/sticker-heart-cry.svg";
import sticker_heart_hi from "./heart/sticker-heart-hi.svg";
import sticker_heart_hug from "./heart/sticker-heart-hug.svg";
import sticker_heart_laugh from "./heart/sticker-heart-laugh.svg";
import sticker_heart_like from "./heart/sticker-heart-like.svg";
import sticker_heart_love from "./heart/sticker-heart-love.svg";
import sticker_heart_sad from "./heart/sticker-heart-sad.svg";

import sticker_guy_angry from "./guy/sticker-guy-angry.svg";
import sticker_guy_confused from "./guy/sticker-guy-confused.svg";
import sticker_guy_cool from "./guy/sticker-guy-cool.svg";
import sticker_guy_happy from "./guy/sticker-guy-happy.svg";
import sticker_guy_kiss from "./guy/sticker-guy-kiss.svg";
import sticker_guy_like from "./guy/sticker-guy-like.svg";
import sticker_guy_please from "./guy/sticker-guy-please.svg";
import sticker_guy_roll_eyes from "./guy/sticker-guy-roll-eyes.svg";
import sticker_guy_sleep from "./guy/sticker-guy-sleep.svg";
import sticker_guy_strong from "./guy/sticker-guy-strong.svg";
import sticker_guy_surprize from "./guy/sticker-guy-surprize.svg";
import sticker_guy_think from "./guy/sticker-guy-think.svg";
import sticker_guy_wave from "./guy/sticker-guy-wave.svg";

import sticker_animal_1 from "./animal/sticker-animal-1.svg";
import sticker_animal_2 from "./animal/sticker-animal-2.svg";
import sticker_animal_3 from "./animal/sticker-animal-3.svg";
import sticker_animal_4 from "./animal/sticker-animal-4.svg";
import sticker_animal_5 from "./animal/sticker-animal-5.svg";
import sticker_animal_6 from "./animal/sticker-animal-6.svg";
import sticker_animal_7 from "./animal/sticker-animal-7.svg";
import sticker_animal_8 from "./animal/sticker-animal-8.svg";
import sticker_animal_9 from "./animal/sticker-animal-9.svg";
import sticker_animal_10 from "./animal/sticker-animal-10.svg";

import sticker_tiger_1 from "./tiger/sticker-tiger-1.png";
import sticker_tiger_2 from "./tiger/sticker-tiger-2.png";
import sticker_tiger_3 from "./tiger/sticker-tiger-3.png";
import sticker_tiger_4 from "./tiger/sticker-tiger-4.png";
import sticker_tiger_5 from "./tiger/sticker-tiger-5.png";
import sticker_tiger_6 from "./tiger/sticker-tiger-6.png";
import sticker_tiger_7 from "./tiger/sticker-tiger-7.png";
import sticker_tiger_8 from "./tiger/sticker-tiger-8.png";
import sticker_tiger_9 from "./tiger/sticker-tiger-9.png";
import sticker_tiger_10 from "./tiger/sticker-tiger-10.png";

// import sticker_girl_angrySVG from "./girl/sticker-girl-angry.svg";
// import sticker_girl_confuseSVG from "./girl/sticker-girl-confuse.svg";
// import sticker_girl_crySVG from "./girl/sticker-girl-cry.svg";
// import sticker_girl_thinkSVG from "./girl/sticker-girl-think.svg";
// import sticker_girl_waveSVG from "./girl/sticker-girl-wave.svg";
// import sticker_girl_happySVG from "./girl/sticker-girl-happy.svg";
// import sticker_girl_kissSVG from "./girl/sticker-girl-kiss.svg";
// import sticker_girl_likeSVG from "./girl/sticker-girl-like.svg";
// import sticker_girl_pleaseSVG from "./girl/sticker-girl-please.svg";
// import sticker_girl_roll_eyesSVG from "./girl/sticker-girl-roll-eyes.svg";
// import sticker_girl_sleepSVG from "./girl/sticker-girl-sleep.svg";
// import sticker_girl_surprizeSVG from "./girl/sticker-girl-surprize.svg";

import sticker_type1_1 from "./type1/sticker-type1-1.svg";
import sticker_type1_2 from "./type1/sticker-type1-2.svg";
import sticker_type1_3 from "./type1/sticker-type1-3.svg";
import sticker_type1_4 from "./type1/sticker-type1-4.svg";
import sticker_type1_5 from "./type1/sticker-type1-5.svg";
import sticker_type1_6 from "./type1/sticker-type1-6.svg";
import sticker_type1_7 from "./type1/sticker-type1-7.svg";
import sticker_type1_8 from "./type1/sticker-type1-8.svg";
import sticker_type1_9 from "./type1/sticker-type1-9.svg";
import sticker_type1_10 from "./type1/sticker-type1-10.svg";

import sticker_type2_1 from "./type2/sticker-type2-1.svg";
import sticker_type2_2 from "./type2/sticker-type2-2.svg";
import sticker_type2_3 from "./type2/sticker-type2-3.svg";
import sticker_type2_4 from "./type2/sticker-type2-4.svg";
import sticker_type2_5 from "./type2/sticker-type2-5.svg";
import sticker_type2_6 from "./type2/sticker-type2-6.svg";
import sticker_type2_7 from "./type2/sticker-type2-7.svg";
import sticker_type2_8 from "./type2/sticker-type2-8.svg";
import sticker_type2_9 from "./type2/sticker-type2-9.svg";
import sticker_type2_10 from "./type2/sticker-type2-10.svg";

import sticker_type3_1 from "./type3/sticker-type3-1.svg";
import sticker_type3_2 from "./type3/sticker-type3-2.svg";
import sticker_type3_3 from "./type3/sticker-type3-3.svg";
import sticker_type3_4 from "./type3/sticker-type3-4.svg";
import sticker_type3_5 from "./type3/sticker-type3-5.svg";
import sticker_type3_6 from "./type3/sticker-type3-6.svg";
import sticker_type3_7 from "./type3/sticker-type3-7.svg";
import sticker_type3_8 from "./type3/sticker-type3-8.svg";
import sticker_type3_9 from "./type3/sticker-type3-9.svg";
import sticker_type3_10 from "./type3/sticker-type3-10.svg";

import sticker_type4_1 from "./type4/sticker-type4-1.svg";
import sticker_type4_2 from "./type4/sticker-type4-2.svg";
import sticker_type4_3 from "./type4/sticker-type4-3.svg";
import sticker_type4_4 from "./type4/sticker-type4-4.svg";
import sticker_type4_5 from "./type4/sticker-type4-5.svg";
import sticker_type4_6 from "./type4/sticker-type4-6.svg";
import sticker_type4_7 from "./type4/sticker-type4-7.svg";
import sticker_type4_8 from "./type4/sticker-type4-8.svg";
import sticker_type4_9 from "./type4/sticker-type4-9.svg";
import sticker_type4_10 from "./type4/sticker-type4-10.svg";

import sticker_type5_1 from "./type5/sticker-type5-1.svg";
import sticker_type5_2 from "./type5/sticker-type5-2.svg";
import sticker_type5_3 from "./type5/sticker-type5-3.svg";
import sticker_type5_4 from "./type5/sticker-type5-4.svg";
import sticker_type5_5 from "./type5/sticker-type5-5.svg";
import sticker_type5_6 from "./type5/sticker-type5-6.svg";
import sticker_type5_7 from "./type5/sticker-type5-7.svg";
import sticker_type5_8 from "./type5/sticker-type5-8.svg";
import sticker_type5_9 from "./type5/sticker-type5-9.svg";
import sticker_type5_10 from "./type5/sticker-type5-10.svg";

import sticker_type6_1 from "./type6/sticker-type6-1.svg";
import sticker_type6_2 from "./type6/sticker-type6-2.svg";
import sticker_type6_3 from "./type6/sticker-type6-3.svg";
import sticker_type6_4 from "./type6/sticker-type6-4.svg";
import sticker_type6_5 from "./type6/sticker-type6-5.svg";
import sticker_type6_6 from "./type6/sticker-type6-6.svg";
import sticker_type6_7 from "./type6/sticker-type6-7.svg";
import sticker_type6_8 from "./type6/sticker-type6-8.svg";
import sticker_type6_9 from "./type6/sticker-type6-9.svg";
import sticker_type6_10 from "./type6/sticker-type6-10.svg";

import sticker_type7_1 from "./type7/sticker-type7-1.svg";
import sticker_type7_2 from "./type7/sticker-type7-2.svg";
import sticker_type7_3 from "./type7/sticker-type7-3.svg";
import sticker_type7_4 from "./type7/sticker-type7-4.svg";
import sticker_type7_5 from "./type7/sticker-type7-5.svg";
import sticker_type7_6 from "./type7/sticker-type7-6.svg";
import sticker_type7_7 from "./type7/sticker-type7-7.svg";
import sticker_type7_8 from "./type7/sticker-type7-8.svg";
import sticker_type7_9 from "./type7/sticker-type7-9.svg";
import sticker_type7_10 from "./type7/sticker-type7-10.svg";

import sticker_type8_1 from "./type8/sticker-type8-1.svg";
import sticker_type8_2 from "./type8/sticker-type8-2.svg";
import sticker_type8_3 from "./type8/sticker-type8-3.svg";
import sticker_type8_4 from "./type8/sticker-type8-4.svg";
import sticker_type8_5 from "./type8/sticker-type8-5.svg";
import sticker_type8_6 from "./type8/sticker-type8-6.svg";
import sticker_type8_7 from "./type8/sticker-type8-7.svg";
import sticker_type8_8 from "./type8/sticker-type8-8.svg";
import sticker_type8_9 from "./type8/sticker-type8-9.svg";
import sticker_type8_10 from "./type8/sticker-type8-10.svg";

import sticker_type9_1 from "./type9/sticker-type9-1.svg";
import sticker_type9_2 from "./type9/sticker-type9-2.svg";
import sticker_type9_3 from "./type9/sticker-type9-3.svg";
import sticker_type9_4 from "./type9/sticker-type9-4.svg";
import sticker_type9_5 from "./type9/sticker-type9-5.svg";
import sticker_type9_6 from "./type9/sticker-type9-6.svg";
import sticker_type9_7 from "./type9/sticker-type9-7.svg";
import sticker_type9_8 from "./type9/sticker-type9-8.svg";
import sticker_type9_9 from "./type9/sticker-type9-9.svg";
import sticker_type9_10 from "./type9/sticker-type9-10.svg";

import sticker_type10_1 from "./type10/sticker-type10-1.svg";
import sticker_type10_2 from "./type10/sticker-type10-2.svg";
import sticker_type10_3 from "./type10/sticker-type10-3.svg";
import sticker_type10_4 from "./type10/sticker-type10-4.svg";
import sticker_type10_5 from "./type10/sticker-type10-5.svg";
import sticker_type10_6 from "./type10/sticker-type10-6.svg";
import sticker_type10_7 from "./type10/sticker-type10-7.svg";
import sticker_type10_8 from "./type10/sticker-type10-8.svg";
import sticker_type10_9 from "./type10/sticker-type10-9.svg";
import sticker_type10_10 from "./type10/sticker-type10-10.svg";

export const stickersConfig = [
  {
    title: "Hearts",
    stickers: [
      { name: "sticker-heart-arrow", img: sticker_heart_arrow },
      { name: "sticker-heart-broken", img: sticker_heart_broken },
      { name: "sticker-heart-confused", img: sticker_heart_confused },
      { name: "sticker-heart-cry", img: sticker_heart_cry },
      { name: "sticker-heart-hi", img: sticker_heart_hi },
      { name: "sticker-heart-hug", img: sticker_heart_hug },
      { name: "sticker-heart-laugh", img: sticker_heart_laugh },
      { name: "sticker-heart-like", img: sticker_heart_like },
      { name: "sticker-heart-love", img: sticker_heart_love },
      { name: "sticker-heart-sad", img: sticker_heart_sad },
    ],
  },
  {
    title: "Mature man",
    stickers: [
      { name: "sticker-guy-angry", img: sticker_guy_angry },
      { name: "sticker-guy-confused", img: sticker_guy_confused },
      { name: "sticker-guy-cool", img: sticker_guy_cool },
      { name: "sticker-guy-happy", img: sticker_guy_happy },
      { name: "sticker-guy-kiss", img: sticker_guy_kiss },
      { name: "sticker-guy-like", img: sticker_guy_like },
      { name: "sticker-guy-please", img: sticker_guy_please },
      { name: "sticker-guy-roll_eyes", img: sticker_guy_roll_eyes },
      { name: "sticker-guy-sleep", img: sticker_guy_sleep },
      { name: "sticker-guy-strong", img: sticker_guy_strong },
      { name: "sticker-guy-surprize", img: sticker_guy_surprize },
      { name: "sticker-guy-think", img: sticker_guy_think },
      { name: "sticker-guy-wave", img: sticker_guy_wave },
    ],
  },
  {
    title: "Animal",
    stickers: [
      { name: "sticker-animal-1", img: sticker_animal_1 },
      { name: "sticker-animal-2", img: sticker_animal_2 },
      { name: "sticker-animal-3", img: sticker_animal_3 },
      { name: "sticker-animal-4", img: sticker_animal_4 },
      { name: "sticker-animal-5", img: sticker_animal_5 },
      { name: "sticker-animal-6", img: sticker_animal_6 },
      { name: "sticker-animal-7", img: sticker_animal_7 },
      { name: "sticker-animal-8", img: sticker_animal_8 },
      { name: "sticker-animal-9", img: sticker_animal_9 },
      { name: "sticker-animal-10", img: sticker_animal_10 },
    ],
  },
  {
    title: "Tiger",
    stickers: [
      { name: "sticker-tiger-1", img: sticker_tiger_1 },
      { name: "sticker-tiger-2", img: sticker_tiger_2 },
      { name: "sticker-tiger-3", img: sticker_tiger_3 },
      { name: "sticker-tiger-4", img: sticker_tiger_4 },
      { name: "sticker-tiger-5", img: sticker_tiger_5 },
      { name: "sticker-tiger-6", img: sticker_tiger_6 },
      { name: "sticker-tiger-7", img: sticker_tiger_7 },
      { name: "sticker-tiger-8", img: sticker_tiger_8 },
      { name: "sticker-tiger-9", img: sticker_tiger_9 },
      { name: "sticker-tiger-10", img: sticker_tiger_10 },
    ],
  },
  {
    title: "Whishes",
    stickers: [
      { name: "sticker-type1-1", img: sticker_type1_1 },
      { name: "sticker-type1-2", img: sticker_type1_2 },
      { name: "sticker-type1-3", img: sticker_type1_3 },
      { name: "sticker-type1-4", img: sticker_type1_4 },
      { name: "sticker-type1-5", img: sticker_type1_5 },
      { name: "sticker-type1-6", img: sticker_type1_6 },
      { name: "sticker-type1-7", img: sticker_type1_7 },
      { name: "sticker-type1-8", img: sticker_type1_8 },
      { name: "sticker-type1-9", img: sticker_type1_9 },
      { name: "sticker-type1-10", img: sticker_type1_10 },
    ],
  },
  {
    title: "Reactions",
    stickers: [
      { name: "sticker-type2-1", img: sticker_type2_1 },
      { name: "sticker-type2-2", img: sticker_type2_2 },
      { name: "sticker-type2-3", img: sticker_type2_3 },
      { name: "sticker-type2-4", img: sticker_type2_4 },
      { name: "sticker-type2-5", img: sticker_type2_5 },
      { name: "sticker-type2-6", img: sticker_type2_6 },
      { name: "sticker-type2-7", img: sticker_type2_7 },
      { name: "sticker-type2-8", img: sticker_type2_8 },
      { name: "sticker-type2-9", img: sticker_type2_9 },
      { name: "sticker-type2-10", img: sticker_type2_10 },
    ],
  },
  {
    title: "Pets",
    stickers: [
      { name: "sticker-type3-1", img: sticker_type3_1 },
      { name: "sticker-type3-2", img: sticker_type3_2 },
      { name: "sticker-type3-3", img: sticker_type3_3 },
      { name: "sticker-type3-4", img: sticker_type3_4 },
      { name: "sticker-type3-5", img: sticker_type3_5 },
      { name: "sticker-type3-6", img: sticker_type3_6 },
      { name: "sticker-type3-7", img: sticker_type3_7 },
      { name: "sticker-type3-8", img: sticker_type3_8 },
      { name: "sticker-type3-9", img: sticker_type3_9 },
      { name: "sticker-type3-10", img: sticker_type3_10 },
    ],
  },
  {
    title: "Mood",
    stickers: [
      { name: "sticker-type4-1", img: sticker_type4_1 },
      { name: "sticker-type4-2", img: sticker_type4_2 },
      { name: "sticker-type4-3", img: sticker_type4_3 },
      { name: "sticker-type4-4", img: sticker_type4_4 },
      { name: "sticker-type4-5", img: sticker_type4_5 },
      { name: "sticker-type4-6", img: sticker_type4_6 },
      { name: "sticker-type4-7", img: sticker_type4_7 },
      { name: "sticker-type4-8", img: sticker_type4_8 },
      { name: "sticker-type4-9", img: sticker_type4_9 },
      { name: "sticker-type4-10", img: sticker_type4_10 },
    ],
  },
  {
    title: "Bee",
    stickers: [
      { name: "sticker-type5-1", img: sticker_type5_1 },
      { name: "sticker-type5-2", img: sticker_type5_2 },
      { name: "sticker-type5-3", img: sticker_type5_3 },
      { name: "sticker-type5-4", img: sticker_type5_4 },
      { name: "sticker-type5-5", img: sticker_type5_5 },
      { name: "sticker-type5-6", img: sticker_type5_6 },
      { name: "sticker-type5-7", img: sticker_type5_7 },
      { name: "sticker-type5-8", img: sticker_type5_8 },
      { name: "sticker-type5-9", img: sticker_type5_9 },
      { name: "sticker-type5-10", img: sticker_type5_10 },
    ],
  },
  {
    title: "Street Food",
    stickers: [
      { name: "sticker-type6-1", img: sticker_type6_1 },
      { name: "sticker-type6-2", img: sticker_type6_2 },
      { name: "sticker-type6-3", img: sticker_type6_3 },
      { name: "sticker-type6-4", img: sticker_type6_4 },
      { name: "sticker-type6-5", img: sticker_type6_5 },
      { name: "sticker-type6-6", img: sticker_type6_6 },
      { name: "sticker-type6-7", img: sticker_type6_7 },
      { name: "sticker-type6-8", img: sticker_type6_8 },
      { name: "sticker-type6-9", img: sticker_type6_9 },
      { name: "sticker-type6-10", img: sticker_type6_10 },
    ],
  },
  {
    title: "Koala Jim",
    stickers: [
      { name: "sticker-type7-1", img: sticker_type7_1 },
      { name: "sticker-type7-2", img: sticker_type7_2 },
      { name: "sticker-type7-3", img: sticker_type7_3 },
      { name: "sticker-type7-4", img: sticker_type7_4 },
      { name: "sticker-type7-5", img: sticker_type7_5 },
      { name: "sticker-type7-6", img: sticker_type7_6 },
      { name: "sticker-type7-7", img: sticker_type7_7 },
      { name: "sticker-type7-8", img: sticker_type7_8 },
      { name: "sticker-type7-9", img: sticker_type7_9 },
      { name: "sticker-type7-10", img: sticker_type7_10 },
    ],
  },
  {
    title: "Avocado",
    stickers: [
      { name: "sticker-type8-1", img: sticker_type8_1 },
      { name: "sticker-type8-2", img: sticker_type8_2 },
      { name: "sticker-type8-3", img: sticker_type8_3 },
      { name: "sticker-type8-4", img: sticker_type8_4 },
      { name: "sticker-type8-5", img: sticker_type8_5 },
      { name: "sticker-type8-6", img: sticker_type8_6 },
      { name: "sticker-type8-7", img: sticker_type8_7 },
      { name: "sticker-type8-8", img: sticker_type8_8 },
      { name: "sticker-type8-9", img: sticker_type8_9 },
      { name: "sticker-type8-10", img: sticker_type8_10 },
    ],
  },
  {
    title: "Love Heart",
    stickers: [
      { name: "sticker-type9-1", img: sticker_type9_1 },
      { name: "sticker-type9-2", img: sticker_type9_2 },
      { name: "sticker-type9-3", img: sticker_type9_3 },
      { name: "sticker-type9-4", img: sticker_type9_4 },
      { name: "sticker-type9-5", img: sticker_type9_5 },
      { name: "sticker-type9-6", img: sticker_type9_6 },
      { name: "sticker-type9-7", img: sticker_type9_7 },
      { name: "sticker-type9-8", img: sticker_type9_8 },
      { name: "sticker-type9-9", img: sticker_type9_9 },
      { name: "sticker-type9-10", img: sticker_type9_10 },
    ],
  },
  {
    title: "Tom Cat",
    stickers: [
      { name: "sticker-type10-1", img: sticker_type10_1 },
      { name: "sticker-type10-2", img: sticker_type10_2 },
      { name: "sticker-type10-3", img: sticker_type10_3 },
      { name: "sticker-type10-4", img: sticker_type10_4 },
      { name: "sticker-type10-5", img: sticker_type10_5 },
      { name: "sticker-type10-6", img: sticker_type10_6 },
      { name: "sticker-type10-7", img: sticker_type10_7 },
      { name: "sticker-type10-8", img: sticker_type10_8 },
      { name: "sticker-type10-9", img: sticker_type10_9 },
      { name: "sticker-type10-10", img: sticker_type10_10 },
    ],
  },
];

import { useEffect, useState } from "react";

export const useConfirmMail = () => {
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);

  useEffect(() => {
    const currentUrl = window.location.href;
    if (currentUrl.includes("/confirm/")) {
      setIsOpenConfirm(true);
    }
  }, []);
  return { isOpenConfirm };
};

import InfiniteScroll from "react-infinite-scroll-component";
import cn from "classnames";

import { CardProfile } from "components/atomsKit/Elements";

// @ts-ignore
import classes from "./SearchList.module.scss";

interface IProps {
  cardsList: {
    external_id: number;
    chat_uid: string;
    public_external_id: number;
    name: string;
    age: number;
    liked?: boolean;
    video: boolean;
    search_img: string;
    online: boolean;
    avatar: string;
    city_name: string;
    country_code: string;
    wink: number | boolean;
  }[];
  handleProfile: (id: number) => void;
  handleChat: (chat_uid: string) => void;
  handleLike: (external_id: number) => void;
  handlePlayVideo: (external_id: number) => void;
  handlCardPhoto: any;
  isVideoList?: boolean;
  handleScroll: any;
  handleLetters: (external_id: number) => void;
  screenVideoImg?: (external_id: number) => void;
}

const SearchList = ({
  isVideoList,
  cardsList,
  handleProfile,
  handleChat,
  handleLike,
  handleScroll,
  handlePlayVideo,
  handlCardPhoto,
  handleLetters,
  screenVideoImg,
}: IProps) => {
  return (
    <>
      <InfiniteScroll
        dataLength={cardsList.length}
        next={handleScroll}
        hasMore={true}
        scrollableTarget="forScroll"
        scrollThreshold={0.7}
        loader={<></>}
      >
        <></>
      </InfiniteScroll>
      <ul
        className={cn(isVideoList ? classes.menuVideo : classes.menu)}
        id={"forScroll"}
      >
        {cardsList.map((item, index) => (
          <CardProfile
            screenVideoImg={screenVideoImg}
            key={index}
            external_id={item.external_id}
            public_external_id={item.public_external_id}
            handleProfile={handleProfile}
            name={item.name}
            age={item.age}
            isLiked={!!item.liked}
            video={item.video}
            img={item.search_img}
            handleChat={handleChat}
            chat_uid={item.chat_uid}
            handleLike={handleLike}
            handlePlayVideo={handlePlayVideo}
            online={!!item.online}
            handlCardPhoto={handlCardPhoto}
            isVideoCard={isVideoList}
            avatar={item.avatar}
            city_name={item.city_name}
            country_icon={item.country_code}
            handleLetters={handleLetters}
            wink={item.wink}
          />
        ))}
      </ul>
    </>
  );
};

export default SearchList;

import { Hearts } from "react-loader-spinner";
import cn from "classnames";

import { MIRROR } from "data/mirrorConfig";

// @ts-ignore
import classes from "./Loader.module.scss";

interface IProps {
  isLoading?: boolean;
  fullScreen?: boolean;
  forDiv?: boolean;
}

const Loader = ({ isLoading, fullScreen, forDiv }: IProps) => {
  return (
    <>
      {forDiv ? (
        <div className={classes.forDivWrap}>
          <div className={classes.loader}>
            <Hearts
              color={
                // @ts-ignore
                MIRROR[process.env.REACT_APP_MIRROR_NAME].COLOR_LOADER
              }
              ariaLabel="hearts-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </div>
      ) : (
        isLoading && (
          <div
            className={cn(classes.wrap, fullScreen && classes.wrap_fullScreen)}
          >
            <Hearts
              height="80"
              width="80"
              color={
                // @ts-ignore
                MIRROR[process.env.REACT_APP_MIRROR_NAME].COLOR_LOADER
              }
              ariaLabel="hearts-loading"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        )
      )}
    </>
  );
};

export default Loader;

import { useState } from "react";

import {
  ModalWindowModern,
  Box,
  Button,
  CloseButton,
  ProgressBarPagination,
} from "components/atomsKit/Elements";

import { steepConfig, steepJSX } from "./steepConfig";

// @ts-ignore
import classes from "./ModalHowToStart.module.scss";

interface IProps {
  closeModal?: any;
  modalIsOpen?: any;
}

const ModalHowToStart = ({ closeModal, modalIsOpen }: IProps) => {
  const [steep, setSteep] = useState(0);

  return (
    <>
      <ModalWindowModern
        modalIsOpen={modalIsOpen}
        hideCloseButton
        closeModal={closeModal}
      >
        <div className={classes.wrap}>
          <CloseButton onClick={closeModal} />
          <h2 className={classes.titleSteep}>How to start</h2>
          <Box marginBottom="20px">
            <div className={classes.wrapPictureSteep}>
              {
                // @ts-ignore
                steepJSX[steep]()
              }
            </div>
          </Box>

          <ProgressBarPagination steep={steep} length={steepConfig.length} />

          <h3 className={classes.titleSteep}>{steepConfig[steep].title}</h3>
          <p className={classes.description}>{steepConfig[steep].text}</p>
          {steep < steepConfig.length - 1 ? (
            <>
              <Button
                text="Continue"
                className={classes.btn}
                onClick={() => {
                  setSteep((prev) =>
                    prev < steepConfig.length - 1 ? prev + 1 : 0
                  );
                }}
              />
              <Button
                text="Skip all"
                isWhite
                className={classes.btn}
                margin="12px 0 0 0"
                onClick={closeModal}
              />
            </>
          ) : (
            <Button
              text="Find your match"
              className={classes.btn}
              margin="12px 0 0 0"
              onClick={closeModal}
            />
          )}
        </div>
      </ModalWindowModern>
    </>
  );
};

export default ModalHowToStart;

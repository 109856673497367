import {
  Box,
  PageWrap,
  InfoGirl,
  InputModern,
  Button,
} from "components/atomsKit/Elements";
import {
  NameonChat,
  Textonchat,
  Image,
  Timeonchat,
} from "components/designAtom/Elements";

import useCartPage from "./useCartPage";

import classes from "./CartPage.module.scss";

const CartPage = () => {
  const {
    cart,
    handeDelOption,
    message,
    onChangeMessage,
    specialText,
    onChangeSpecialText,
    handleSendGift,
    profile,
  } = useCartPage();

  return (
    <PageWrap height100>
      <div className={classes.wrap}>
        <div className={classes.list}>
          <NameonChat
            text="Cart"
            size={22}
            textAlign={"center"}
            margin="24px 0"
          />
          <div className={classes.card}>
            {cart.map((gift) => (
              <div className={classes.option} key={gift.id}>
                <button onClick={() => handeDelOption(gift.id)}>
                  <Image name="cross-circle-red" className={classes.del} />
                </button>
                <Box flex align="center">
                  <img width="100" alt="" src={gift.image} loading="lazy" />
                  <NameonChat text={gift.name} margin="0 0 0 14px" />
                </Box>
                <Textonchat text={gift.description} />
                <Box flex marginTop="14px">
                  <Textonchat text="Price:" size={10} />
                  <Textonchat
                    text={`${+gift.price} credits`}
                    size={10}
                    margin="0 0 0 12px"
                  />
                </Box>
              </div>
            ))}

            <Box className={classes.info}>
              <NameonChat
                text="Gift addresee details:"
                size={20}
                margin="24px 0"
              />

              <Box flex align="center">
                <NameonChat text="Addressee:" size={18} margin="0 14px 0 0" />
                {profile?.id && (
                  <InfoGirl
                    imgLink={profile?.avatar}
                    public_external_id={profile?.public_external_id}
                    name={profile?.name}
                    age={profile?.age}
                    country_icon={profile?.flag}
                    city={profile?.city}
                  />
                )}
              </Box>
            </Box>
            <NameonChat
              text="Message for the member"
              size={18}
              margin="24px 0 0 0"
            />
            <InputModern
              isTextArea
              infoText={`${message.length}/300`}
              value={message}
              onChange={onChangeMessage}
            />
            <Timeonchat
              text={
                "The member will receive this gift with your printed message (maximum 300 symbols) on a greeting card"
              }
            />
            <NameonChat
              text="Special gift giving preferences"
              size={18}
              margin="24px 0 0 0"
            />
            <InputModern
              isTextArea
              infoText={`${specialText.length}/300`}
              value={specialText}
              onChange={onChangeSpecialText}
            />
            <Box flex align="center" justify="center">
              <NameonChat
                text="Total gift price:"
                size={18}
                margin="0 14px 0 0"
              />
              <NameonChat
                text={`${cart.reduce(
                  (accumulator, currentValue) =>
                    accumulator + +currentValue.price,
                  0
                )} credits`}
                size={18}
              />
            </Box>
            <Button
              disabled={cart.length === 0}
              onClick={handleSendGift}
              text="Buy gifts"
              icon="x1"
              margin="10px 0 0 0"
              className={classes.sendBtn}
            />
          </div>
        </div>
      </div>
    </PageWrap>
  );
};

export default CartPage;

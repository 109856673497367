import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  ModalWindowModern,
  InputModern,
} from "components/atomsKit/Elements";
import { NameonChat, Image } from "components/designAtom/Elements";

import { togleGlobalModal } from "redux/interface/slice";

import { postRequest } from "utils/requests";

import { configTutorial } from "./configTutorial";

// @ts-ignore
import classes from "./ModalResetPassword.module.scss";

interface IProps {
  modalIsOpen: boolean;
  closeModal: any;
  currentEmail: string;
}

const ModalResetPassword = ({
  modalIsOpen,
  closeModal,
  currentEmail,
}: IProps) => {
  const [email, setEmail] = useState(currentEmail);

  const dispatch = useDispatch();

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const handleResetPass = (e: any) => {
    e.preventDefault();
    postRequest("/api/v3/auth/password-restore", { email: email })
      .then((res: any) => {
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: res?.message,
            btnText: "Got it",
          })
        );
      })
      .catch(() => {
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: "Something went wrong!",
            btnText: "Got it",
          })
        );
      });
  };

  return (
    <ModalWindowModern modalIsOpen={modalIsOpen} closeModal={closeModal}>
      <form onSubmit={handleResetPass}>
        <Box
          className={classes.modalBody}
          flex
          align="center"
          justify="center"
          directionColumn
        >
          <NameonChat
            className={classes.title}
            text="Enter the email used during sing-up process, which you want to restore"
            size={18}
            textAlign={"center"}
          />

          <InputModern
            placeholder="Your Email"
            value={email}
            onChange={onChangeEmail}
          />

          <Button
            text="Reset password"
            margin="24px 0 0 0"
            fitContent
            padding="0 40px 0 40px"
          />
          {configTutorial.map((item) => (
            <Box marginTop="10px" className={classes.wrapInstruction}>
              {item.name && (
                <NameonChat
                  className={classes.title}
                  text={item.name}
                  size={16}
                  textAlign={"center"}
                  margin="0 0 10px 0"
                />
              )}

              <Box flex align="center">
                <Image
                  size={50}
                  name={`${item.icon}_${process.env.REACT_APP_MIRROR_NAME}`}
                />
                <Box marginLeft="10px">
                  <NameonChat
                    className={classes.title}
                    text={item.title}
                    size={14}
                    margin="0 0 10px 0"
                  />

                  <NameonChat text={item.text} size={12} />
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </form>
    </ModalWindowModern>
  );
};

export default ModalResetPassword;

import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";

import {
  ChatList,
  ChatFilterPanel,
  PageWrap,
  EmptyState,
} from "components/atomsKit/Elements";

import useChatPage from "./useBlockListPage";

const BlockListPage = () => {
  const {
    chatList,
    handleIsOnline,
    isOnline,
    setSearch,
    search,
    handleCard,
    handleScroll,
  } = useChatPage();

  const navigate = useNavigate();

  return (
    <>
      <ChatFilterPanel
        handleIsOnline={handleIsOnline}
        isOnline={isOnline}
        search={search}
        setSearch={setSearch}
      />

      <PageWrap mutationHeight={72} height100>
        <>
          {chatList.length > 0 ? (
            <>
              <InfiniteScroll
                dataLength={chatList?.length}
                next={handleScroll}
                hasMore={true}
                scrollableTarget="forScroll"
                scrollThreshold={0.7}
                loader={<></>}
              >
                <></>
              </InfiniteScroll>

              <ChatList
                scrollableTarget="forScroll"
                chatList={chatList}
                handleCard={handleCard}
              />
            </>
          ) : (
            <EmptyState
              config={{
                title: "No chats found",
                description:
                  "To find someone to chat with, you may use the following options:",
                buttons: [
                  {
                    text: "Like,",
                    onClick: () => {
                      navigate("/like");
                    },
                  },
                  {
                    text: "Search",
                    onClick: () => {
                      navigate("/search");
                    },
                  },
                  // {
                  //   text: "Video",
                  //   onClick: () => {
                  //     navigate("/menu/video");
                  //   },
                  // },
                ],
              }}
            />
          )}
        </>
      </PageWrap>
    </>
  );
};

export default BlockListPage;

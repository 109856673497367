import cn from "classnames";

// @ts-ignore
import classes from "./Stick.module.scss";

interface IProps {
  text: string;
  className?: string;
  error?: boolean;
}

const Stick = ({ text, className, error }: IProps) => {
  return <p className={cn(classes.stick, error && classes.stick_error, className)}>{text}</p>;
};

export default Stick;

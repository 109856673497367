import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMyProfile } from "redux/auth/operations";
import { selectMyProfile } from "redux/auth/selectors";

import { getQueryParam } from "utils/common";

const useChatPage = () => {
  const dispatch = useDispatch();

  const filterGifts = getQueryParam("filter") || "all";

  const orders = useSelector(selectMyProfile)?.orders?.filter((order) => {
    return (
      filterGifts === "all" ||
      (filterGifts === "progress" && order.status === 0) ||
      (filterGifts === "approved" && order.status === 1) ||
      (filterGifts === "declined" && order.status === 2)
    );
  });

  useEffect(() => {
    dispatch(getMyProfile());
    // eslint-disable-next-line
  }, []);

  return { orders };
};

export default useChatPage;

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  ModalAlert,
  Avatar,
  NameAge,
  Box,
  ProgressBar,
  ProgressBarDay,
  Button,
  ModalIMBRAUpload,
} from "components/atomsKit/Elements";
import {
  Timeonchat,
  Textonchat,
  NameonChat,
  Image,
} from "components/designAtom/Elements";

import { togleGlobalModal, loaderOn, loaderOff } from "redux/interface/slice";

import { text } from "data/text";

import { getRequest, postRequest } from "utils/requests";

import cn from "classnames";

// @ts-ignore
import classes from "./ModalContactRequest.module.scss";
import { useDispatch } from "react-redux";

const ModalContactRequest = ({
  imgLink,
  name,
  age,
  external_id,
  public_external_id,
  modalIsOpen,
  closeModal,
  price = {},
  user_detail,
}) => {
  const [info, setInfo] = useState();
  const [openVerifyIMBRA, setOpenVerifyIMBRA] = useState(false);
  const [openFormImbra, setOpenFormImbra] = useState(false);
  const [imbraInfo, setImbraInfo] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getRequest(
      `/api/user/spentCreditsForContactRequest?female_external_id=${external_id}`
    ).then((res) => setInfo(res));

    getImbraStatus();
    // eslint-disable-next-line
  }, []);

  const getImbraStatus = () => {
    postRequest(`/api/v3/contact-request/imbra`, {
      womanExternalID: external_id,
    }).then((res) => setImbraInfo(res?.result?.status));
  };

  const handleMakeRequest = () => {
    postRequest("/api/chat/contactRequest", {
      female_external_id: external_id,
    }).then((res) => {
      dispatch(
        togleGlobalModal({
          isOpen: "open",
          text: res?.message,
          btnText: "Got it",
        })
      );
    });
  };

  const uploadIMBRA = (e) => {
    const womanExternalID = external_id;
    const file = e?.target?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("womanExternalID", womanExternalID);
    dispatch(loaderOn());
    postRequest(`/api/v3/contact-request/imbra/file`, formData).then((res) => {
      if (res?.status) {
        dispatch(loaderOff());
        getImbraStatus();
      } else {
        dispatch(loaderOff());
        dispatch(
          togleGlobalModal({
            isOpen: "open",
            text: "Something went wrong!",
            btnText: "Got it",
          })
        );
      }
    });
  };

  const CheckCircleVerification = () => {
    return (
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        // fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.4853 4.51469C19.2188 2.24823 16.2053 1 13 1C9.79464 1 6.78114 2.24823 4.51469 4.51469C2.24819 6.78119 1 9.79469 1 13C1 16.2053 2.24819 19.2188 4.51469 21.4853C6.78119 23.7518 9.79464 25 13 25C16.2053 25 19.2188 23.7518 21.4853 21.4853C23.7518 19.2188 25 16.2053 25 13C25 9.79469 23.7518 6.78123 21.4853 4.51469ZM13 23.5938C7.15858 23.5938 2.40625 18.8414 2.40625 13C2.40625 7.15858 7.15858 2.40625 13 2.40625C18.8414 2.40625 23.5938 7.15858 23.5938 13C23.5938 18.8414 18.8414 23.5938 13 23.5938Z"
          fill="#EBEDFA"
          stroke="#EBEDFA"
          strokeWidth="0.5"
        />
        <path
          d="M18.7919 9.20302C18.5146 8.93232 18.0648 8.93232 17.7874 9.20306L11.5146 15.3261L8.21263 12.1029C7.93526 11.8322 7.48547 11.8322 7.20806 12.1029C6.93065 12.3736 6.93065 12.8127 7.20806 13.0835L11.0123 16.7969C11.151 16.9323 11.3328 17 11.5146 17C11.6964 17 11.8782 16.9323 12.0169 16.7969L18.7919 10.1836C19.0694 9.91286 19.0694 9.47381 18.7919 9.20302Z"
          fill="#EBEDFA"
          stroke="#EBEDFA"
          strokeWidth="0.5"
        />
      </svg>
    );
  };

  return (
    <ModalAlert
      modalIsOpen={modalIsOpen}
      closeModal={closeModal}
      textAlertBtn="Make request"
      icon="x1"
      allertMessage={"Contact request"}
    >
      {openVerifyIMBRA && (
        <ModalIMBRAUpload
          closeModal={() => setOpenVerifyIMBRA(false)}
          openFormImbra={openFormImbra}
          setOpenFormImbra={setOpenFormImbra}
          uploadIMBRA={uploadIMBRA}
        />
      )}

      <Box flex align="center" marginBottom="10px">
        <Avatar big imgLink={imgLink} />
        <Box marginLeft="10px">
          <NameAge name={name} age={age} />
          <Timeonchat text={`ID: ${public_external_id}`} />
        </Box>
      </Box>

      <Box className={classes.wrap}>
        <Textonchat text={text.CONTACT_MODAL_TEXT} />

        <Box className={classes.card} marginTop="10px">
          <NameonChat text={"Credits counter"} />
          <Textonchat
            text={
              "You should spend the required amount of credits in communication (chat, letter, etc.)."
            }
            margin="10px 0 0 0"
          />
          <Box flex align="center" justify={"between"} marginTop="10px">
            <NameonChat text={"Credits"} size={12} />
            <NameonChat
              text={`of ${price.GET_CONTACT_LIMIT} Credits`}
              size={12}
            />
          </Box>
          <ProgressBar val={info?.message} from={price.GET_CONTACT_LIMIT} />
        </Box>
        <Box className={classes.card} marginTop="10px">
          <NameonChat text={"Chat days"} />
          <Textonchat
            text={
              "You should spend at least the required number of days in communication to get to know your partner better."
            }
            margin="10px 0 0 0"
          />
          <ProgressBarDay
            maxDay={15}
            usedDay={info?.spendDays}
            labelText={"Days left"}
            margin={"10px 0 0 0"}
          />
        </Box>

        {info?.message >= price.GET_CONTACT_LIMIT && info?.spendDays >= 10 && (
          <>
            {user_detail.phone &&
            user_detail.email_confirmed &&
            user_detail.real_name ? (
              <Box className={classes.card} marginTop="10px">
                <NameonChat text={"IMBRA verification"} />
                {imbraInfo === 0 ? (
                  <>
                    <Textonchat
                      text={
                        "Please wait while we check the correctness of filling out the form."
                      }
                      margin="10px 0 0 0"
                    />
                    <Box flex align="center" justify="center" directionColumn>
                      <Textonchat
                        text="Status"
                        margin="10px 0 10px 0"
                        className={classes.textDecor}
                      />
                      <Image name="verify_status_verification" size={60} />
                      <Textonchat
                        text="Status: Verification of documents"
                        margin="10px 0 0 0"
                        className={classes.textDecor}
                      />
                    </Box>
                    <Button
                      text="In progress"
                      margin="20px 0 0 0"
                      className={classes.btnDecor}
                    />
                  </>
                ) : !!imbraInfo ? (
                  <>
                    <Textonchat
                      text={
                        "You have incorrectly filled out the IMBRA. Please upload a new scan based on the corrections listed below. Review our comments carefully. If you have any questions or misunderstandings, please contact our support team."
                      }
                      margin="10px 0 0 0"
                    />
                    <Box flex align="center" justify="center" directionColumn>
                      <Textonchat
                        text="Status"
                        margin="10px 0 10px 0"
                        className={classes.textDecor}
                      />
                      <Image name="verify_status_canceled" size={60} />
                      <Textonchat
                        text="Canceled"
                        margin="10px 0 0 0"
                        className={classes.textDecor}
                      />
                    </Box>
                    <Button
                      text="Please fill out the IMBRA again."
                      margin="20px 0 0 0"
                      onClick={() => {
                        setOpenVerifyIMBRA(true);
                      }}
                    />
                  </>
                ) : imbraInfo === 2 ? (
                  <>
                    <Textonchat
                      text={
                        "You have filled in the IMBRA correctly. You may now make a contact request."
                      }
                      margin="10px 0 0 0"
                    />
                    <Box flex align="center" justify="center" directionColumn>
                      <Textonchat
                        text="Status"
                        margin="10px 0 10px 0"
                        className={classes.textDecor}
                      />
                      <Image name="verify_status_verified" size={60} />
                      <Textonchat
                        text="Verified"
                        margin="10px 0 0 0"
                        className={classes.textDecor}
                      />
                    </Box>
                    <Button
                      text="Verified"
                      margin="20px 0 0 0"
                      className={classes.btnDecor}
                    />
                  </>
                ) : (
                  <>
                    <Textonchat
                      text={
                        "In order to exchange contact information, please download the IMBRA form, fill it out and upload it for verification."
                      }
                      margin="10px 0 0 0"
                    />
                    <Box flex align="center" justify="center" directionColumn>
                      <Textonchat text="Status" margin="10px 0 10px 0" />
                      <Image name="verify_status_upload" />
                      <Textonchat
                        text="Please upload IMBRA"
                        margin="10px 0 0 0"
                      />
                    </Box>
                    <Button
                      text="Start verify"
                      margin="20px 0 0 0"
                      onClick={() => {
                        setOpenVerifyIMBRA(true);
                      }}
                    />
                  </>
                )}
              </Box>
            ) : (
              <Box className={classes.card} marginTop="10px">
                <NameonChat text={"GET VERIFIED AND BECOME A VIP USER"} />
                <Textonchat
                  text={
                    "Dear user, become verified and get special offers and VIP status"
                  }
                  margin="10px 0 0 0"
                />
                <Box flex align="center" justify={"between"} marginTop="10px">
                  <Box
                    flex
                    directionColumn
                    justify="center"
                    align="center"
                    className={cn(user_detail.phone && classes.active)}
                  >
                    <CheckCircleVerification />
                    <NameonChat text={" Step 1"} margin={"4px 0 0 0"} />
                  </Box>
                  <Box
                    flex
                    directionColumn
                    justify="center"
                    align="center"
                    className={cn(
                      user_detail.email_confirmed && classes.active
                    )}
                  >
                    <CheckCircleVerification />
                    <NameonChat text={" Step 2"} margin={"4px 0 0 0"} />
                  </Box>
                  <Box
                    flex
                    directionColumn
                    justify="center"
                    align="center"
                    className={cn(user_detail.real_name && classes.active)}
                  >
                    <CheckCircleVerification />
                    <NameonChat text={" Step 3"} margin={"4px 0 0 0"} />
                  </Box>
                </Box>
                <Button
                  text="Start verify"
                  margin="20px 0 0 0"
                  onClick={() => {
                    navigate("/profile/verify");
                  }}
                />
              </Box>
            )}
          </>
        )}

        <Textonchat text={"Request price"} margin="10px 0 0 0" />
        <NameonChat text={`${price.GET_CONTACT} Credits`} />
        <Button
          text="Make request"
          icon={"x1"}
          margin="10px 0 0 0"
          disabled={imbraInfo === 2 ? false : true}
          onClick={handleMakeRequest}
        />
      </Box>
    </ModalAlert>
  );
};

export default ModalContactRequest;

import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectUser } from "redux/auth/selectors";
import { getReFetchAction } from "redux/interface/selectors";
import { fetchChatList } from "redux/chat/operations";

import { postRequest } from "utils/requests";

const useChatPage = () => {
  const isFirstRender = useRef(true);
  const [isOnline, setIsOnline] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(1);

  const [chatList, setChatList] = useState([]);
  const userInfo = useSelector(selectUser);

  const { filterChat } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const socketAction = useSelector(getReFetchAction);

  useEffect(() => {
    getFirsPage();
    // eslint-disable-next-line
  }, [filterChat, search, isOnline]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (socketAction.action === "message") getFirsPage();
    // eslint-disable-next-line
  }, [socketAction]);

  const getFirsPage = () => {
    setPagination(1);
    fetchChatListNew({
      ONLINE_STATUS: isOnline,
      SEARCH: search,
      page: 1,
    });
    dispatch(
      fetchChatList({
        user_id: userInfo?.user_info?.external_id,
        ONLINE_STATUS: isOnline,
        SEARCH: search,
        CHAT_TYPE: filterChat === "ALL" ? "" : filterChat,
        page: 1,
      })
    );
  };

  const fetchChatListNew = (params) => {
    postRequest("/api/chat/blockListByUserID", params).then((res) => {
      setChatList(
        Object.values(res.chats).map((item) => ({
          chat_uid: item?.chat_uid,
          external_id: item?.sender_external_id,
          name: item?.name,
          age: item?.age,
          womanAvatars: { avatar: item?.photo_link },
        }))
      );
    });
  };

  const handleIsOnline = () => {
    setIsOnline(!isOnline);
  };

  const handleCard = (chat_uid, id) => {
    navigate(`/chat/${chat_uid}?profile=${id}`);
  };
  
  const handleScroll = () => {
    setPagination(pagination + 1);
    dispatch(
      fetchChatList({
        user_id: userInfo?.user_info?.external_id,
        ONLINE_STATUS: isOnline,
        SEARCH: search,
        CHAT_TYPE: filterChat === "ALL" ? "" : filterChat,
        page: pagination + 1,
      })
    );
  };

  return {
    chatList,
    handleIsOnline,
    isOnline,
    setSearch,
    search,
    handleCard,
    handleScroll,
  };
};

export default useChatPage;

import { useState } from "react";
import { useSelector } from "react-redux";

import { selectInfo } from "redux/auth/selectors";

const useTutorial = () => {
  const [choseTutorial, setChoseTutorial] = useState();
  const [idSelectTopic, setIdSelectTopic] = useState();

  const tutorialList = useSelector(selectInfo).tutorial;

  return {
    tutorialList,
    setChoseTutorial,
    choseTutorial,
    idSelectTopic,
    setIdSelectTopic,
  };
};

export default useTutorial;

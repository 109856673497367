import cn from "classnames";
import { Icon } from "components/atomsKit/Elements";
import { Image as IconSvg } from "components/designAtom/Elements";

// @ts-ignore
import classes from "./Button.module.scss";

interface IProps {
  onClick?: () => void;
  icon?: string;
  iconSvg?: string;
  text?: string;
  className?: string;
  classNameIcon?: string;
  isWhite?: boolean;
  disabled?: boolean;
  borderOff?: boolean;
  color?: string;
  type?: "button" | "submit" | "reset" | undefined;
  noBg?: boolean;
  fitContent?: boolean;
  margin?: string;
  padding?: string;
  bgImg?: boolean;
}

const Button = ({
  onClick,
  text,
  className,
  classNameIcon,
  isWhite,
  icon,
  iconSvg,
  disabled,
  borderOff,
  color,
  type,
  noBg,
  fitContent,
  margin,
  padding,
  bgImg,
}: IProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={cn(
        classes.btn,
        isWhite && classes.btn_white,
        borderOff && classes.btn_borderOff,
        noBg && classes.btn_noBg,
        fitContent && classes.btn_fitContent,
        className
      )}
      style={{
        border: borderOff ? undefined : `1px solid ${color}`,
        margin: margin,
        padding: padding,
      }}
    >
      {iconSvg && (
        <IconSvg
          bgImg={bgImg}
          name={iconSvg}
          color={color}
          className={cn(
            classes.iconContainer,
            !text && classes.iconContainer_noText
          )}
          classNameIcon={classNameIcon}
        />
      )}
      {icon && (
        <div className={classes.iconContainer}>
          <Icon
            icon={icon}
            size={"100%"}
            className={cn(isWhite && classes.icon_white)}
            color={color}
          />
        </div>
      )}
      <p style={{ color: color }}>{text}</p>
    </button>
  );
};

export default Button;

import { forwardRef } from "react";
import cn from "classnames";

// @ts-ignore
import classes from "./Box.module.scss";

interface IProps {
  style?: object;
  refBox?: any;
  className?: string;
  id?: string;
  width100?: boolean;
  height100?: boolean;
  children?: any;
  flex?: boolean;
  directionColumn?: boolean;
  align?: "center" | "end" | "start";
  justify?: "center" | "end" | "around" | "between" | "start";
  position?: "absolute" | "relative" | "fixed" | "static" | "sticky";
  margin?: string;
  marginLeft?: string;
  marginRight?: string;
  marginTop?: string;
  marginBottom?: string;
  padding?: string;
  bgColor?: string;
  onClick?: (value: any) => void;
  onTouchStart?: any;
  onTouchMove?: any;
  onTouchEnd?: any;
  zIndex?: number;
}

const Box = forwardRef(
  ({
    refBox,
    id,
    flex,
    directionColumn,
    align,
    justify,
    width100,
    height100,
    position,
    className,
    children,
    margin,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    padding,
    bgColor,
    onClick,
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    style,
    zIndex,
  }: IProps) => {
    return (
      <div
        ref={refBox}
        id={id}
        onClick={onClick}
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        style={
          margin ||
          marginLeft ||
          marginRight ||
          marginBottom ||
          marginTop ||
          bgColor ||
          padding ||
          zIndex
            ? {
                margin: margin,
                marginLeft: marginLeft,
                marginRight: marginRight,
                marginTop: marginTop,
                marginBottom: marginBottom,
                background: bgColor,
                padding: padding,
                zIndex: zIndex,
              }
            : style
            ? style
            : undefined
        }
        className={cn(
          classes.wrapBox,
          flex && classes.flex,
          align && classes[`align_${align}`],
          justify && classes[`justify_${justify}`],
          position && classes[`position_${position}`],
          width100 && classes.width100,
          height100 && classes.height100,
          directionColumn && classes.directionColumn,
          className && className
        )}
      >
        {children}
      </div>
    );
  }
);

export default Box;

import Select, { components } from "react-select";
import cn from "classnames";

import { DropdownIndicatorPart } from "./Parts/Parts";
import { selectStyles } from "./Parts/selectStyles";

import classes from "./SelectDropDown.module.scss";

const SelectDropDown = ({
  placeholder,
  options,
  label = "",
  value,
  onInputChange = () => {},
  inputValue = "",
  onChange,
  className = {},
  isSearchable = false,
  isClearable = false,
  error = false,
  isMulti = false,
  menuPlacement = "bottom",
  formatOptionLabel = "",
  offDropDownContainer = false,
}) => {
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <span>
        {formatOptionLabel} {children}
      </span>
    </components.SingleValue>
  );

  const IndicatorsContainer = ({ children, ...props }) => (
    <components.IndicatorsContainer {...props}>
      <span>{children}</span>
    </components.IndicatorsContainer>
  );

  return (
    <div className={cn(classes.wrapSelect, className)}>
      {label && <h2 className={cn(classes.title)}>{label}</h2>}

      <div className={classes.selectWrap}>
        <Select
          onInputChange={onInputChange}
          inputValue={inputValue || undefined}
          isMulti={isMulti}
          error={error}
          value={value}
          placeholder={placeholder}
          isSearchable={isSearchable}
          isClearable={isClearable}
          components={{
            IndicatorSeparator: undefined,
            SingleValue,
            IndicatorsContainer: offDropDownContainer
              ? DropdownIndicatorPart
              : IndicatorsContainer,
          }}
          hideSelectedOptions={false}
          onChange={onChange}
          options={options}
          styles={selectStyles}
          tabSelectsValue={false}
          menuPlacement={menuPlacement}
        />
      </div>
    </div>
  );
};

export default SelectDropDown;

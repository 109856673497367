import { usePerformanceMetrics } from "hooks";
import { MetricsLogger } from "@cabify/prom-react";

interface IProps {
  children?: any;
}

const MetricsInnerWrap = ({ children }: IProps) => {
  usePerformanceMetrics();

  return (
    <>
      {window?.location?.href?.includes("consoleMetrics=1") && (
        <MetricsLogger />
      )}
      {children}
    </>
  );
};

export default MetricsInnerWrap;

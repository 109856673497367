import { useSelector } from "react-redux";

import { selectPrice } from "redux/auth/selectors";
import {} from "utils/common";

const usePrice = () => {
  const allPrice = useSelector(selectPrice);

  const price = allPrice && allPrice?.length > 0 ? allPrice[0] : {};

  return {
    SENT_TEXT: parseInt(price?.SENT_TEXT), //
    SENT_IMAGE: parseInt(price?.SENT_IMAGE), //
    SENT_AUDIO: parseInt(price?.SENT_AUDIO),
    SENT_AUDIO_NEW: parseInt(price?.SENT_AUDIO_NEW),
    GET_AUDIO: parseInt(price?.GET_AUDIO),
    GET_AUDIO_NEW: parseInt(price?.GET_AUDIO_NEW), //
    SENT_VIDEO: parseInt(price?.SENT_VIDEO),
    SENT_VIDEO_NEW: parseInt(price?.SENT_VIDEO_NEW),
    GET_VIDEO: parseInt(price?.GET_VIDEO),
    GET_VIDEO_NEW: parseInt(price?.GET_VIDEO_NEW), //
    SENT_WINK: parseInt(price?.SENT_WINK),
    SENT_STICKER: parseInt(price?.SENT_STICKER), // ??????????????
    GET_CONTACT: parseInt(price?.GET_CONTACT),
    GET_CONTACT_LIMIT: parseInt(price?.GET_CONTACT_LIMIT),
    GET_MEETING: parseInt(price?.GET_MEETING),
    GET_VIDEOCALL: parseInt(price?.GET_VIDEOCALL),
    GET_ONEMINUTEVIDEOCALL: parseInt(price?.GET_ONEMINUTEVIDEOCALL),
    SENT_LIKE: parseInt(price?.SENT_LIKE),
    SENT_MAIL: parseInt(price?.SENT_MAIL),
    SENT_MAIL_FIRST: parseInt(price?.SENT_MAIL_FIRST), //
    SENT_MAIL_SECOND: parseInt(price?.SENT_MAIL_SECOND), //
    READ_MAIL: parseInt(price?.READ_MAIL), //????????????????
    SENT_IMAGE_MAIL: parseInt(price?.SENT_IMAGE_MAIL),
    SENT_AUDIO_MAIL: parseInt(price?.SENT_AUDIO_MAIL),
    SENT_AUDIO_MAIL_NEW: parseInt(price?.SENT_AUDIO_MAIL_NEW), //
    SENT_VIDEO_MAIL: parseInt(price?.SENT_VIDEO_MAIL),
    SENT_VIDEO_MAIL_NEW: parseInt(price?.SENT_VIDEO_MAIL_NEW), //
    GET_IMAGE_MAIL: parseInt(price?.GET_IMAGE_MAIL), //
    GET_AUDIO_MAIL: parseInt(price?.GET_AUDIO_MAIL),
    GET_AUDIO_MAIL_NEW: parseInt(price?.GET_AUDIO_MAIL_NEW),
    GET_VIDEO_MAIL: parseInt(price?.GET_VIDEO_MAIL),
    GET_VIDEO_MAIL_NEW: parseInt(price?.GET_VIDEO_MAIL_NEW),
    GET_VIDEO_SHOW: parseInt(price?.GET_VIDEO_SHOW),
  };
};

export default usePrice;

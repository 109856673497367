import {
  ModalWindowModern,
  Box,
  Button,
  ImbraForm,
  Icon,
} from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";

// @ts-ignore
import classes from "./ModalIMBRAUpload.module.scss";

const ModalIMBRAUpload = ({
  closeModal,
  openFormImbra,
  setOpenFormImbra,
  uploadIMBRA,
}) => {
  return (
    <ModalWindowModern modalIsOpen zIndex={1001} hideCloseButton>
      {openFormImbra ? (
        <Box className={classes.wrapFormImbra}>
          <ImbraForm
            closeModal={() => {
              setOpenFormImbra(false);
            }}
          />
        </Box>
      ) : (
        <Box className={classes.wrap}>
          <button onClick={closeModal} className={classes.closebtn}>
            <Icon icon={"ok"} className={classes.icon} color={"#CDD3DA"} />
          </button>
          <h3 className={classes.title}>Why IMBRA is important?</h3>
          <p className={classes.description}>
            We are concerned about the safety of our users. We also cannot
            violate the standards set by the current US law. Therefore, before
            contact details can be transferred or a meeting can take place, we
            ask that you fill out the appropriate questionnaire. We emphasize
            that, once again, this is not an initiative set forth by the
            platform, but rather a requirement of the law. Thank you for your
            patience and understanding.
          </p>
          <Box flex align="center" justify="center" directionColumn>
            <h3 className={classes.titleBtnBlock}>IMBRA verification steps</h3>

            <Image name="imbra_download" className={classes.img} />
            <Button
              className={classes.btn}
              text="Fill out the IMBRA form"
              iconSvg="cloud-download-white"
              onClick={() => setOpenFormImbra(true)}
            />
            <Image name="imbra_upload" className={classes.img} />

            <label className={classes.btn}>
              <input
                style={{ display: "none" }}
                type="file"
                id="popup_inp_file1"
                onChange={(e) => {
                  uploadIMBRA(e);
                  closeModal();
                }}
              />
              <Button
                className={classes.btnDecor}
                text="Upload a scan/photo of your IMBRA form"
                iconSvg="cloud-upload-white"
                type="button"
              />
            </label>

            <Image name="imbra_waiting" className={classes.img} />
            <p>{`Status: waiting for IMBRA scan/photo`}</p>
          </Box>
        </Box>
      )}
    </ModalWindowModern>
  );
};

export default ModalIMBRAUpload;

import { Box, Button } from "components/atomsKit/Elements";
import { Image, NameonChat, Textonchat } from "components/designAtom/Elements";

// @ts-ignore
import classes from "./EmptyState.module.scss";

interface IProps {
  className?: string;
  config?: any;
  img?: string;
}

const EmptyState = ({ config, img }: IProps) => {
  return (
    <Box>
      <Box
        flex
        align="center"
        justify="center"
        directionColumn
        height100
        padding={"0 16px"}
      >
        <Image name={img || "sweetheart_image"} className={classes.emptyImg} />
        <NameonChat
          margin="20px 0 0 0"
          size={18}
          text={config.title}
          textAlign={"center"}
        />
        <Textonchat
          textAlign={"center"}
          margin="20px 0 20px 0"
          text={config.description}
        />
        <Box flex>
          {config.buttons.map((item: any) => (
            <Button
              key={item.text}
              fitContent
              onClick={item.onClick}
              isWhite
              text={item.text}
              borderOff
            />
          ))}
        </Box>
        {config.onClickClear && (
          <Button
            margin="20px 0 0 0"
            fitContent
            padding="0 16px 0 16px"
            onClick={config.onClickClear}
            isWhite
            text={config.textClearBtn}
          />
        )}
      </Box>
    </Box>
  );
};

export default EmptyState;

import { useNavigate } from "react-router-dom";
import { NameonChat } from "components/designAtom/Elements";

import { Button, Box } from "components/atomsKit/Elements";

import useMenuPage from "./useMenuPage";

import { menuOptions } from "data/config";

import useHandleActiveBlocks from "hooks/useHandleActiveBlocks";

import { useHandleRouting } from "hooks";

import { MIRROR } from "data/mirrorConfig";

// @ts-ignore
import classes from "./MenuPage.module.scss";

const MenuPage = () => {
  // const [Template, setTemplate] = useState();
  const { user_avatar, user_name } = useMenuPage();
  const navigate = useNavigate();
  const activeBlocks = useHandleActiveBlocks() as any;

  const { launch } = useHandleRouting();

  return (
    <Box className={classes.wrap}>
      <Box flex className={classes.profileWrap}>
        {user_avatar ? (
          <img
            alt=""
            className={classes.avatar}
            src={user_avatar}
            loading="lazy"
          />
        ) : (
          <Box
            className={classes.avatarNone}
            flex
            align="center"
            justify="center"
          >
            <NameonChat
              textAlign={"center"}
              text={user_name?.slice(0, 2)}
              className={classes.nameUser}
              size={20}
            />
          </Box>
        )}
        <Box width100 marginLeft="10px" flex directionColumn justify={"center"}>
          <Button
            bgImg
            onClick={() => {
              navigate("/profile");
            }}
            text="My profile"
            isWhite
            iconSvg={"userMenu"}
            className={classes.btnProfile}
            classNameIcon={classes.btnIcon}
          />
          <Button
            text={"Add credits"}
            iconSvg={"copy_white"}
            onClick={() => {
              launch();
            }}
            className={classes.addBtn}
          />
        </Box>
      </Box>
      <ul>
        {menuOptions.map((option) => (
          <li key={option.name}>
            {(!option.nameRules ||
              (option.nameRules &&
                (option.logicOperationRules
                  ? activeBlocks[option.nameRules]
                  : !activeBlocks[option.nameRules]))) && (
              <Button
                className={classes.btn}
                isWhite
                onClick={() => navigate(option.link)}
                text={option.name}
                iconSvg={option.icon}
                bgImg
                classNameIcon={classes.btnIcon}
              />
            )}
          </li>
        ))}
      </ul>
      <Box
        flex
        align="center"
        justify="center"
        directionColumn
        className={classes.ligal}
      >
        <p>
          © 2024 {/* @ts-ignore */}
          {MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME.charAt(
            0
          ).toUpperCase() +
            // @ts-ignore
            MIRROR[process.env.REACT_APP_MIRROR_NAME].NAME.slice(
              1
            ).toLowerCase()}
          .com
        </p>
        <p>All rights reserved</p>
        <a
          href={`mailto:${
            // @ts-ignore
            MIRROR[process.env.REACT_APP_MIRROR_NAME].SUPPORT_MAIL
          }`}
          className={classes.supportMail}
        >
          {/* @ts-ignore */}
          {MIRROR[process.env.REACT_APP_MIRROR_NAME].SUPPORT_MAIL}
        </a>
      </Box>
    </Box>
  );
};

export default MenuPage;

import {
  ChatFilterPanel,
  PageWrap,
  SendGiftCard,
} from "components/atomsKit/Elements";

import { configFilterSendGifts } from "data/config";

import useSendGiftsPage from "./useSendGiftsPage";

import { formatDateToMMMMDDYY } from "utils/common";
// @ts-ignore
import classes from "./SendGiftsPage.module.scss";

const SendGiftsPage = () => {
  const { orders } = useSendGiftsPage();

  return (
    <>
      <ChatFilterPanel
        handleIsOnline={() => {}}
        isOnline
        configFilterChat={configFilterSendGifts("sentgifts")}
      />

      <PageWrap
        mutationHeight={61}
        height100
        scroll
        padding="0 20px"
        className={classes.pageWrap}
      >
        <ul>
          {orders?.map((item: any, index: number) => (
            <li key={index}>
              <SendGiftCard
                id={item?.product?.external_id}
                image={item?.product?.image}
                name={item?.product?.name}
                price={item?.product?.price}
                public_external_id={item?.woman_profile?.public_external_id}
                nameGirl={item?.woman_profile?.name}
                ageGirl={item?.woman_profile?.age}
                avatarGirl={item?.woman_profile?.avatar}
                date_created={formatDateToMMMMDDYY(item?.date_created)}
                status={item?.status}
              />
            </li>
          ))}
        </ul>
      </PageWrap>
    </>
  );
};

export default SendGiftsPage;

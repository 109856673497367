import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getMyProfile } from "redux/auth/operations";
import { selectMyProfile } from "redux/auth/selectors";

import { getQueryParam } from "utils/common";

const useChatPage = () => {
  const dispatch = useDispatch();

  const filterGifts = getQueryParam("filter") || "all";

  const orders = useSelector(selectMyProfile)?.contact_request?.filter(
    (contact) => {
      return (
        filterGifts === "all" ||
        (filterGifts === "progress" && contact.status === 0) ||
        (filterGifts === "approved" && contact.status === 1) ||
        (filterGifts === "declined" && contact.status === 2)
      );
    }
  );

  useEffect(() => {
    dispatch(getMyProfile());
    // eslint-disable-next-line
  }, []);

  return { orders };
};

export default useChatPage;

import { Box, Button, AccordionMenu } from "components/atomsKit/Elements";
import { NameonChat } from "components/designAtom/Elements";

import useTutorialPage from "./useTutorialPage";
// @ts-ignore
import classes from "./TutorialPage.module.scss";

const TutorialPage = () => {
  const {
    tutorialList,
    setChoseTutorial,
    choseTutorial,
    idSelectTopic,
    setIdSelectTopic,
  } = useTutorialPage() as any;

  return (
    <Box className={classes.wrap}>
      <Box flex directionColumn align="center" justify="center" padding="24px">
        {!choseTutorial &&
          tutorialList?.map((item: any, index: number) => (
            <button
              className={classes.btn}
              onClick={() => setChoseTutorial(item)}
            >
              <p className={classes.number}>{index + 1}</p>
              {item.title}
            </button>
          ))}
        {choseTutorial && (
          <>
            <Box
              flex
              position={"relative"}
              width100
              align="center"
              justify="center"
              onClick={() => setChoseTutorial()}
            >
              <Button
                fitContent
                text="back"
                borderOff
                noBg
                color="#474F5C"
                className={classes.btnBack}
              />
              <NameonChat
                text={choseTutorial.title}
                margin="0 0 10px 0"
                size={18}
              />
            </Box>
            <Box className={classes.wrapSubMenu}>
              <div
                className={classes.answer}
                dangerouslySetInnerHTML={{
                  __html: choseTutorial.text,
                }}
              />
              {choseTutorial?.category_list?.map((item: any) => (
                <Box className={classes.option}>
                  <AccordionMenu
                    text={item.title}
                    isOpenOption={idSelectTopic === item.id}
                    handleOption={() =>
                      setIdSelectTopic(
                        idSelectTopic === item.id ? null : item.id
                      )
                    }
                  >
                    <div
                      className={classes.answer}
                      dangerouslySetInnerHTML={{
                        __html: item.subcategory_list[0]?.text,
                      }}
                    />
                  </AccordionMenu>
                </Box>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default TutorialPage;

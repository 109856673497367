import {
  ModalWindowModern,
  Box,
  CloseButton,
} from "components/atomsKit/Elements";

import { Image } from "components/designAtom/Elements";

// @ts-ignore
import classes from "./ModalCongratulation.module.scss";

interface IProps {
  closeModal?: any;
  modalIsOpen?: any;
  textHeader: string;
  icon?: string;
  titleText?: string;
  messages?: string[];
  textBtn: string;
  onClick?: any;
}

const ModalCongratulation = ({
  closeModal,
  modalIsOpen,
  textHeader,
  icon,
  titleText,
  messages,
  textBtn,
  onClick,
}: IProps) => {
  return (
    <>
      <ModalWindowModern
        modalIsOpen={modalIsOpen}
        hideCloseButton
        closeModal={closeModal}
      >
        <div className={classes.wrap}>
          <p className={classes.text}>{textHeader}</p>
          <CloseButton onClick={closeModal} />
          {icon && <Image name={icon} />}

          <Box flex align="center" justify={"start"}>
            <p className={classes.title}>
              <strong>{titleText}</strong>
            </p>
          </Box>
          <ul>
            {messages?.map((item: any, index) => (
              <p className={classes.title} key={index}>
                {item}
              </p>
            ))}
          </ul>
          <button className={classes.btnAction} onClick={onClick}>
            {textBtn}
          </button>
        </div>
      </ModalWindowModern>
    </>
  );
};

export default ModalCongratulation;

import { useEffect } from 'react';
import { sendErrToSentry } from 'utils/common';
import { useLocation } from 'react-router-dom';

export const useGtagPageView = () => {
    const location = useLocation();

    function trackPageView() {
        try {
            const gtagFunction = (window as any)?.gtag;

            if (typeof gtagFunction === 'function') {
                gtagFunction('event', 'page_view', {
                    page_location: window?.location?.href,
                    page_path: window?.location?.pathname,
                });
            }
        } catch (error) {
            sendErrToSentry(error as Error);
        }
    }

    useEffect(() => {
        trackPageView();
    }, [location.pathname]);
}

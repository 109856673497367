import cn from "classnames";

import newMainIMG from "img/icons/icons";

// @ts-ignore
import classes from "./Image.module.scss";

interface IProps {
  className?: string;
  classNameIcon?: string;
  name: string;
  bgImg?: boolean;
  color?: string;
  imgRotate?: number;
  size?: number;
}

const Image = ({
  name,
  bgImg,
  color,
  imgRotate,
  size,
  className,
  classNameIcon,
}: IProps) => {
  return (
    <>
      {!bgImg ? (
        <img
          src={newMainIMG.images[`${name}.svg`]}
          alt=""
          className={cn(classes.iconWrap, className)}
          style={{ width: `${size}px` }}
          loading="lazy"
        />
      ) : (
        <span
          className={cn(classes.bgWrap, className, classNameIcon)}
          style={{
            backgroundColor: color,
            mask: `url(${newMainIMG.images[`${name}.svg`]}) center no-repeat`,
            WebkitMask: `url(${
              newMainIMG.images[`${name}.svg`]
            }) center no-repeat`,
            maskSize: "contain",
            WebkitMaskSize: "contain",
          }}
        />
      )}
    </>
  );
};

export default Image;

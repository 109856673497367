import * as Sentry from "@sentry/react";
import { Button } from "components/atomsKit/Elements";

const TestSentryButton = () => {
  const sendTestError = () => {
    Sentry.captureException(new Error("Test Error for Sentry"));
  };

  const sendTestMessage = () => {
    Sentry.captureMessage("Test Message for Sentry");
  };

  return (
    <div>
      <Button onClick={sendTestError} text="Test Error for Sentry" />
      <Button onClick={sendTestMessage} text="Test Message for Sentry" />
    </div>
  );
};

export default TestSentryButton;

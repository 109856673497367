import { useState } from "react";
import { useSelector } from "react-redux";

import { selectUser } from "redux/auth/selectors";
import { postRequest } from "utils/requests";

export const useInitUserBirthdayModal = () => {
  const userState = useSelector(selectUser)?.user_info;

  const [isUserBirthdayModal, setIsUserBirthdayModal] = useState(false);
  const [birthdayBonusCredits, setBirthdayBonusCredits] = useState(0);

  const openUserBirthdayModal = () => setIsUserBirthdayModal(true);
  const closeUserBirthdayModal = () => {
    setIsUserBirthdayModal(false);
  };

  const checkUserBirthday = () => {
    const date = new Date();
    const [currentYear, currentMonth, currentDay] = [
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    ];
    const userBirthdayLocalStorage =
      localStorage.getItem("check_user_birthday") ?? "";
    const yearLocalStorage = userBirthdayLocalStorage?.split("-")[0];
    const checkUserBirthdayStatus = userBirthdayLocalStorage?.split("_")[1];
    const [userBirthMonth, userBirthDay] = [
      userState?.b_month,
      userState?.b_day,
    ];
    if (
      (+userBirthMonth === currentMonth + 1 &&
        (+userBirthDay === currentDay || currentDay - userBirthDay <= 7) &&
        !+checkUserBirthdayStatus) ||
      +yearLocalStorage !== currentYear
    ) {
      postRequest("/api/v3/front-sync/birthday").then((res) => {
        if (res) {
          if (res?.status && +res?.result > 0) {
            setBirthdayBonusCredits(res?.result);
            openUserBirthdayModal();
          }
          localStorage.setItem(
            "check_user_birthday",
            `${currentYear}-${currentMonth + 1}-${currentDay}_${Number(
              res?.status
            )}`
          );
        }
      });
    }
  };

  return {
    isUserBirthdayModal,
    openUserBirthdayModal,
    closeUserBirthdayModal,
    checkUserBirthday,
    birthdayBonusCredits,
  };
};

import {
  GirlProfile,
  ModalAlert,
  ModalContactRequest,
  SendWinkModal,
} from "components/atomsKit/Elements";

import useUserPage from "./useUserPage";
import usePrice from "hooks/usePrice";

import useHandleActiveBlocks from "hooks/useHandleActiveBlocks";

// @ts-ignore
// import classes from "./TemplatePage.module.scss";

const UserPage = () => {
  const {
    profile,
    handleSimilar,
    videoLink,
    handleVideoPlay,
    setIsOpenModalVideo,
    isOpenModalVideo,
    handleFavorite,
    handleChat,
    handleLike,
    handleLetters,
    isLikeGirl,
    handleSayHello,
    isOpenModalSayHello,
    setIsOpenModalSayHello,
    isOpenModalContact,
    setIsOpenModalContact,
    handleSendGift,
    similarList,
    sayHalloList,
    isOpenModalWink,
    setIsOpenModalWink,
    handleWink,
    user_detail,
  } = useUserPage();

  const { isContactRequest, isHideGift } = useHandleActiveBlocks();

  const price = usePrice();

  if (!profile) {
    return <></>;
  }

  return (
    <>
      <ModalAlert
        modalIsOpen={isOpenModalSayHello}
        closeModal={() => setIsOpenModalSayHello(false)}
        onClick={() => {
          handleChat(
            profile?.profile?.result?.chat?.chat_uid,
            profile?.profile?.result?.profile?.external_id
          );
        }}
        textAlertBtn="Go to chat"
        icon="x1"
        allertMessage={"Message sent"}
      />
      <SendWinkModal
        config={sayHalloList}
        handleWink={handleWink}
        handleChat={() => {
          handleChat(
            profile?.profile?.result?.chat?.chat_uid,
            profile?.profile?.result?.profile?.external_id
          );
        }}
        name={profile?.profile?.result?.profile?.name}
        modalIsOpen={isOpenModalWink}
        closeModal={() => {
          setIsOpenModalWink(false);
        }}
      />
      {isOpenModalContact && (
        <ModalContactRequest
          modalIsOpen={isOpenModalContact}
          closeModal={() => setIsOpenModalContact(false)}
          imgLink={profile?.avatar?.avatar_title}
          name={profile?.profile?.result?.profile.name}
          age={profile?.profile?.result?.profile?.age}
          external_id={profile?.profile?.result?.profile?.external_id}
          public_external_id={
            profile?.profile?.result?.profile?.public_external_id
          }
          user_detail={user_detail}
          price={price}
        />
      )}

      <GirlProfile
        videoLink={videoLink}
        cardInfo={profile}
        similarList={similarList}
        handleSimilar={handleSimilar}
        handleVideoPlay={handleVideoPlay}
        closeModalVideo={setIsOpenModalVideo}
        isOpenModalVideo={isOpenModalVideo}
        handleFavorite={handleFavorite}
        handleChat={handleChat}
        handleLetters={handleLetters}
        handleLike={handleLike}
        isLikeGirl={isLikeGirl}
        handleSayHello={handleSayHello}
        setIsOpenModalContact={setIsOpenModalContact}
        handleSendGift={handleSendGift}
        isContactRequest={isContactRequest}
        isHideGift={isHideGift}
      />
    </>
  );
};

export default UserPage;

import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { crashCallback } from "utils/common";
import { isInjectedCode } from "utils/sentryFilterInject";

const RELEASE = "mob-app@1.0.0";
const dsn = process.env.REACT_APP_SENTRY;

let isSentryInitialized = false;

if (dsn) {
  Sentry.init({
    dsn: dsn,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
      }),
      Sentry.replayIntegration(),
    ],
    release: RELEASE,
    tracesSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      const errorMessage = hint?.originalException?.message;
      if (isInjectedCode(event)) return null;
      crashCallback(errorMessage);
      return event;
    },
  });

  window.addEventListener("error", (event) => {
    Sentry.captureException(event.error);
  });

  window.addEventListener("unhandledrejection", (event) => {
    Sentry.captureException(event.reason);
  });

  isSentryInitialized = true;
}

export { isSentryInitialized };

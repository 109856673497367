import cn from "classnames";

// @ts-ignore
import Modal from "react-modal";

import { Icon } from "components/atomsKit/Elements";

// @ts-ignore
import classes from "./ModalFilter.module.scss";

interface IProps {
  modalIsOpen?: boolean;
  closeModal: () => void;
  children: React.ReactElement;
  position?: "left" | "right";
}

const ModalFilter = ({
  modalIsOpen,
  closeModal,
  children,
  position,
}: IProps) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={cn(classes.Modal, position === "right" && classes.Modal_right)}
      overlayClassName={classes.Overlay}
      ariaHideApp={false}
    >
      <button
        onClick={closeModal}
        className={cn(
          classes.btn,
          position === "right" ? classes.btn_right : classes.btn_left
        )}
      >
        <Icon icon={"ok"} className={classes.icon} color={"white"} />
      </button>
      {children}
    </Modal>
  );
};

export default ModalFilter;

import { useState, useEffect, useRef } from "react";

import cn from "classnames";

import { Box } from "components/atomsKit/Elements";
import { Image } from "components/designAtom/Elements";

import classes from "./ButtonWithDropdown.module.scss";

const ButtonWithDropdown = ({
  icon = "c3-top-options-btn",
  sizeIcon = 4,
  buttonText = "More",
  children,
  closeOnChange = true,
  outsideClose = false,
}) => {
  const [windowVisible, setWindowVisible] = useState(false);

  const node = useRef();
  const buttonList = useRef();

  useEffect(() => {
    if (windowVisible) {
      document.addEventListener("mousedown", handleClick);
    }
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [windowVisible]);

  const handleClick = (e) => {
    if (
      node.current.contains(e.target) ||
      buttonList.current.contains(e.target)
    ) {
      return;
    }
    setWindowVisible(false);
  };

  const hahdleButton = () => {
    setWindowVisible(!windowVisible);
  };

  useEffect(() => {
    setWindowVisible(false);
  }, [outsideClose]);

  return (
    <div ref={buttonList} className={classes.container}>
      <Box flex justify="center" align="center" directionColumn>
        <button onClick={hahdleButton} className={classes.btn}>
          <Image name={icon} size={sizeIcon} />
          {buttonText !== "" && <p className={classes.text}>{buttonText}</p>}
        </button>
      </Box>
      {windowVisible && (
        <div
          className={cn(classes.windowWrap)}
          ref={node}
          onClick={closeOnChange ? () => setWindowVisible(false) : () => {}}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export default ButtonWithDropdown;

import cn from "classnames";

import { Icon } from "components/atomsKit/Elements";
// @ts-ignore
import classes from "./ButtonIcon.module.scss";

interface IProps {
  onClick?: () => void;
  icon: string;
  iconSize?: string;
  text?: string;
  color?: string;
  className?: string;
  disabled?: boolean;
}

const ButtonIcon = ({
  onClick,
  icon,
  text,
  iconSize = "0.875rem",
  color,
  disabled,
  className,
}: IProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(classes.btn, className)}
      disabled={disabled}
    >
      <Icon size={iconSize} icon={icon} color={color} />
      {text && (
        <p className={classes.text} style={{ color: color }}>
          {text}
        </p>
      )}
    </button>
  );
};

export default ButtonIcon;

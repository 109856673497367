import { useState } from "react";
import TestSentryButton from "utils/errorTest";

const TestPage = () => {
  const [code, setCode] = useState("");

  const handleInputChange = (e) => {
    setCode(e.target.value);
  };

  const renderContent = () => {
    switch (code) {
      case "a3ff4F2d33dd3d2d45ak45jg42g6":
        return <TestSentryButton />;
      default:
        return <div>Enter a valid code to see the content</div>;
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Code"
        value={code}
        onChange={handleInputChange}
        style={{ margin: "20px 0" }}
      />
      {renderContent()}
    </div>
  );
};

export default TestPage;
